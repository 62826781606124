import React from "react"
import caretRight from 'images/booking/caret-right.svg'
import moment from 'moment'

export default class DateLabel extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { startDate, endDate, openCalendar } = this.props;

    const duration = startDate && endDate ? Math.floor(moment.duration(endDate - startDate).asDays()) : 0

    return (
      <div className="comfort-filter border-b">
        <div className="row ml-0 mt-3">
          <div className="col-10">
            <div className="row">
              <h2 onClick={() => openCalendar(true)}>{I18n.t("recreations.filter.dates")}</h2>
            </div>
            <div className="row" onClick={() => openCalendar(true)} >
              {duration > 0 && <div className="col-2 pr-0 pl-0">
                {I18n.t("recreations.filter.selected_nights", {count: duration})}
              </div>}
              <div className="col-10 pr-0 pb-2">
                {startDate && startDate.format("D MMM, dddd")}
                {endDate && (<React.Fragment>&nbsp;―&nbsp;</React.Fragment>) }
                {endDate && endDate.format("D MMM, dddd")}
              </div>
            </div>
          </div>
          <div className="col-2">
            <a className="show-calendar pl-2" onClick={() => openCalendar(true)}>
              <img src={caretRight} alt="caret-right"/>
            </a>
          </div>
        </div>

      </div>
    )
  }
}
