import React from "react"
import mapIconWhite from 'images/2/img/_src/icons/map_icon_white.svg'

export default class ShowMapButton extends React.Component {

  constructor(props) {
    super(props);

  }

  render () {
    const { showMap, isShowMap } = this.props;

    var classValue = ""
    var txt = "2"

    if(isShowMap){
      classValue = "button btn btn-primary hide-map"
      txt = I18n.t('recreations.hide_map_button')

    }else{
      classValue = "button btn btn-primary"
      txt = I18n.t('recreations.show_map_button')
    }

    return (
      <button
        className={classValue}
        id="show_map_button"
        onClick={showMap}
    >
        {!isShowMap &&
      <img src={mapIconWhite} alt="map" />
      }
        {txt}
      </button>
    )
  }
}
