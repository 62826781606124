import React from "react"
import PropTypes from "prop-types"
export default class SearchTerm extends React.Component {
  static defaultProps = {
  };

  constructor(props) {
    super(props);
  }

  onKeyDown(e){
    const { loadRecreations } = this.props;

    if (e.key === 'Enter') {
      loadRecreations({ page: 1, setUrl: true });
    }
  }
  render () {
    const { q, onQChange, loadRecreations } = this.props;

    return (

      <div  className={`filter-block search-term ${q ? "has-selected" : ""}`}>
        <input
          type='search'
          value={q}
          onChange={onQChange}
          placeholder={I18n.t("recreations.filter.search_placeholder")}
          className="form-control py-2 border-right-0 border"
          onKeyDown={(e) => this.onKeyDown(e)}
        />
        <span className="input-group-append">
          <button
            className="btn btn-outline-secondary border-left-0 border button-search"
            type="button"
            onClick={() => loadRecreations({ page: 1, setUrl: true })}
          >
            <i className="fa fa-search"></i>
          </button>
        </span>
      </div>
      )
  }
}
