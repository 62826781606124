import React from "react"

export default class SmallBanners extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    const { smallBanners, showMap } = this.props;



    if (showMap){
      return (<div></div>)
    }else{
    return (
        <section className="recommended border-b">
          <div className="container">
            <div className="row over-scroll">
              {!showMap && smallBanners && smallBanners.map((banner, index) => (
              <div className="recommendedItm col" key={index}>
                <a href={banner.url} className="recommendedItm__wrap">
                  <div className="moreItm__photo recommendedItm__photo">
                    <img
                    src={banner.image_url}
                    alt="more items"
                    className="img-fluid"
                  />
                    </div>
                    <div className="recommendedItm__info">
                      {false && <p>Service</p>}
                      <div className="recommendedItm__desc">
                        <h3>{banner.title}</h3>
                        <p>{banner.subtitle}</p>
                      </div>
                    </div>
                  </a>
                </div>
                ))}
              </div>
            </div>
          </section>
          )
  }
  }
}
