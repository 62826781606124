import React from "react"
import PropTypes from "prop-types"
class BrowseMap extends React.Component {
  static defaultProps = {
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      lat: props.lat,
      lng: props.lng,
      zoom: props.zoom,
      recreations: [],
      loading: false,
      t_point: null,
      b_point: null,
      sel_icon: null,
      def_icon: null,
      map: null,
      initSlider: false,
      max_price: props.max_price,
      min_price: props.min_price,
      price_from: props.price_from,
      price_to: props.price_to,
      selected_property: props.selected_property,
      markers: [],
      only_available: props.only_available,
      selected_locations: props.selected_locations,
      selected_property_types: props.selected_property_types,
      selected_listing_options: props.selected_listing_options,
      locale: props.locale,
      propSlider: null,
      show_filter: false,
      current_marker_index: null
    };
  }

  componentDidMount(){
    const {lat, lng, zoom} = this.state;
    const that = this;
    $('#mapModal').on('show.bs.modal', function (e) {
      that.fixBackground();
      that.initMap('map-box', lat, lng, zoom);
      that.initPriceSlider();
    });

    $('#mapModal').on('hidden.bs.modal', function (e) {
      that.unfixBackground();
      that.setState({visible: false});
      reloadRecreations();
    });

  }

  showModal(){
    const {max_price, min_price} = this.state;

    const selected_property_types = $.map($("input[name='property_types[]']:checked"), function(t){return parseInt(t.value)});
    const selected_listing_options = $.map($("input[name='listing_options[]']:checked"), function(t){return parseInt(t.value)});
    var price_from;
    var price_to;
    if($("#price_from")[0]){
      price_from = parseInt($("#price_from")[0].value);
      price_to = parseInt($("#price_to")[0].value);
    }else{
      price_from = min_price;
      price_to = max_price;
    }

    this.setState({visible: true, price_from: price_from, price_to: price_to, selected_property_types: selected_property_types, selected_listing_options: selected_listing_options }, function(){
      $('#mapModal').modal('show');
    });
  }

  fixBackground(){
    document.body.style.position = 'fixed';
    document.body.style.top = -1 * window.scrollY + 'px';
  }

  unfixBackground(){
    const scrollY = document.body.style.top;
    document.body.style.position = '';
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  }

  componentDidUpdate(){
    const {initSlider} = this.state;

    if (initSlider){

      this.setState({initSlider: false}, () => {
        this.initMobileSlider();
      })

    }
  }

  isMobile(){
    return $('.row').width() < 768;
  }


  toggleFilter(){
    const { show_filter } = this.state;
    this.setState({show_filter: !show_filter})
  }


  initMobileSlider(){
    const { propSlider } = this.state;
    var slider2 = null;
    const slice_count = $(".search-card-slider").length;
    if (this.isMobile() && slice_count  > 0 ){

      const empty = $(".properties-slider .card--empty").length;

      if ( slice_count == 1 ){
        slider2 = $('.properties-slider').slick({
          lazyLoad: 'ondemand',
          mobileFirst: true,
          initialSlide: 0,
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
          arrows: false
        });
        if (!empty){
          this.hoverOn(0);
        }
      }else{


        $('.properties-slider').unbind("beforeChange");

        $('.properties-slider').on("beforeChange", (el, slick, slide, next_slide) => {
          this.hoverOn(Math.floor(next_slide));
          this.hoverOff(Math.floor(slide));
        });
        slider2 = $('.properties-slider').slick({
          lazyLoad: 'ondemand',
          mobileFirst: true,
          initialSlide: 0.9,
          slidesToShow: 1.1,
          slidesToScroll: 1,
          speed: 100,
          dots: false,
          arrows: false
        });


        this.hoverOn(0);
      }

      this.setState({propSlider: slider2, current_marker_index: 0})

    }else{
    }
  }

  navigateToSlide(i){

    const { propSlider } = this.state;
    if (propSlider){
      $(".properties-slider").slick('slideHandler', i + 0.9)
    }
  }


  getPropertiesSearchParams(){

    const { map, price_to, price_from, selected_property_types, selected_listing_options } = this.state;

    var params = {};

    const bounds = map.getBounds()
    const t_point = bounds.getNorthWest();
    const b_point = bounds.getSouthEast();

    params['t_lat'] = t_point.lat;
    params['t_lng'] = t_point.lng;
    params['b_lat'] = b_point.lat;
    params['b_lng'] = b_point.lng;
    params['price_to'] = price_to;
    params['price_from'] = price_from;

    if (selected_property_types.length > 0){
      params['property_types[]'] = selected_property_types;
    }
    if (selected_listing_options.length > 0){
      params['listing_options[]'] = selected_listing_options
    }

    return params;

  }

  hoverOn(i){
    const { markers, def_icon, sel_icon, current_marker_index } = this.state;

    markers[i].setIcon(sel_icon);
    markers[i].setZIndexOffset(300);

    if (current_marker_index != null){
      markers[current_marker_index].setIcon(def_icon);
      markers[current_marker_index].setZIndexOffset(100);
    }

    this.setState({current_marker_index: i})
  }

  hoverOff(i){
    const { markers, def_icon, current_marker_index } = this.state;
    if(!markers[i]){
      return
    }
    markers[i].setIcon(def_icon);
    markers[i].setZIndexOffset(100);
    this.setState({current_marker_index: null})
  }

  changeType(l){
    const { selected_property_types, visible } = this.state;

    if(!visible){
      return
    }

    const sel = selected_property_types.filter(function(e){ return e == l['id'] }).length > 0;
    const without_l = selected_property_types.filter(function(e){ return e != l['id'] });

    selected_property_types.push(l['id']);

    $("#property_type_" + l['id']).click()

    this.setState({selected_property_types: sel ? without_l : selected_property_types}, () => {
      this.loadRecreations()
    })
  }

  changeOption(l){
    const { selected_listing_options, visible } = this.state;
    if(!visible){
      return
    }

    const sel = selected_listing_options.filter(function(e){ return e == l['id'] }).length > 0;
    const without_l = selected_listing_options.filter(function(e){ return e != l['id'] });


    selected_listing_options.push(l['id'])

    $("#listing_option_" + l['id']).click()

    this.setState({selected_listing_options: sel ? without_l : selected_listing_options}, () => {
      this.loadRecreations()
    })

  }


  renderType(l, selecteds){

    const sel = selecteds.filter(function(e){ return e == l['id'] }).length > 0;

    return (
      <label className={["checkbox", sel ? "selected" : ''].join(' ')}>
        {l['name']}
        <input
          type="checkbox"
          name="property_type[]"
          id={"location_" + l['id']}
          checked={sel ? 'checked' : null}
          onChange ={ () => this.changeType(l) }
          value={l['id']} />
        <span className="checkmark"></span>
      </label>

      )
  }
  renderOption(l, selecteds){

    const sel = selecteds.filter(function(e){ return e == l['id'] }).length > 0;

    return (
      <label className={["checkbox", sel ? "selected" : ''].join(' ')}>
        {l['name']}
        <input
          type="checkbox"
          name="listing_option[]"
          id={"location_" + l['id']}
          checked={sel ? 'checked' : null}
          onChange ={() => this.changeOption(l)}
          value={l['id']} />
        <span className="checkmark"></span>
      </label>

      )
}


  renderRecreationSlider(r, locale, i){

    const url = "/" + locale + "/recreations/" + r.slug;

    const hot = r.discount && (r.discount.length > 0);

    return (
      <div
        className={["search-card-slider col", hot ? "card--hot" : ""].join(" ")}
        onMouseEnter ={() => this.hoverOn(i)}
        onMouseLeave ={() => this.hoverOff(i)}
      >
        <div className='search-card-inner'>
          <div className="prop-image">
            <a href={url} target="_blank">
              <img alt="" title="" src={r.photo}/>
            </a>
          </div>
          <div className='prop-description'>
            <div className='prop-location'>{r.location}</div>
            <a href={url} target="_blank">
              <div className='prop-title'>{r.title}</div>
            </a>
            <div className='prop-price'>
              {I18n.t("recreations.form.from")}
              &nbsp;
              {r.average_price_per_person}
              &nbsp;
              {I18n.t("recreations.form.hrn")}
            </div>
            <div className='prop-rating d-flex align-items-center justify-content-between'>
              <div>
                { r.rating.average > 0 && (

                <div className="rate">
                  {Array.from({length: r.rating.average}).map(() => { return (<i className="icon icon-star"></i>) })}
                </div>
                ) }
              </div>
              { r.discount && (
              <div className="label">{r.discount}</div>
              ) }
            </div>
          </div>
          <div className='clear'></div>
  </div>

      </div>
      );
      }

  renderRecreation(r, locale, i){

    const url = "/" + locale + "/recreations/" + r.slug;
    // field "discount" was deleted from property model. But there are "hot?" method.
    const hot = r.discount && (r.discount.length > 0);
    return (
      <div className='col-md-6 search-box' id={'popup-recreation-' + r.id}>
        <div
        className={["search-card", hot ? "card--hot" : ""].join(" ")}
          onMouseEnter ={() => this.hoverOn(i)}
          onMouseLeave ={() => this.hoverOff(i)}
        >
          <a href={url} target="_blank">
            <div className="card__img card__img--slider">
              <img alt="" title="" src={r.photo}/>
            </div>
          </a>
          <a href={url} target="_blank">
            <div className="card__location">{r.location}</div>
            <div className="card__name">{r.title}</div>
            <div className="card__price">
              {I18n.t("recreations.form.from")}
              &nbsp;
              {r.average_price_per_person}
              &nbsp;
              {I18n.t("recreations.form.hrn")}
            </div>
          </a>
          <div className="row">
            <div className="col d-flex align-items-center justify-content-between rating-place">
              <div>
                { r.rating.average > 0 && (

              <div className="rate">
                {Array.from({length: r.rating.average}).map(() => { return (<i className="icon icon-star"></i>) })}
                <span className="rating-number">({r.rating.reviews_count})</span>
              </div>
              ) }
            </div>

              { r.discount && (
              <div className="label">{r.discount}</div>
              ) }

            </div>
          </div>
        </div>
      </div>
      );
      }

  loadRecreations(){
    const { locale, map, loading, propSlider } = this.state;

    if(!map || loading){
      return;
    }

    const search_params = this.getPropertiesSearchParams();

    if (search_params.t_lat == search_params.b_lat){
      return;
    }
    this.setState({loading: true})
    const that = this;
    if (propSlider){
      $(".properties-slider").slick('unslick');
    }
     $.ajax({
       url: "/" + locale + "/recreations/map_search",
       dataType: 'json',
       method: 'POST',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
       data: search_params,
       complete: function(data) {
         const recreations = data.responseJSON;
         that.setState({recreations: recreations, initSlider: true, loading: false}, function(){
           that.showMapMarkers();
         });
       }})
  }




getPropertyPopup(r, locale){
  const url = "/" + locale + "/recreations/" + r.slug;


  const rating = r.rating.average > 0 ? Array.from({length: r.rating.average}).map(() => { return '<i className="icon icon-star"></i>' }).join("") : "";
  const discount = r.discount ? "<div className='label'>"+r.discount+"</div>" : "";
  const d = ["<div class='property-popup'><h3>"+r.title+"</h3><a href='"+url+"' target='_blank'>",
    "<img src="+r.photo+"/></a><div class='prop-description'><h4>"+r.location+"</h4>",
    "<h4>"+I18n.t("recreations.form.from")+ "&nbsp;" + r.average_price_per_person + "&nbsp;" + I18n.t("recreations.form.hrn") +"</h4>",
    "</div></div>"].join("");
    return d;
}

scrollToSlide(i, index){

  const {current_marker_index} = this.state;

  const id = "#popup-recreation-" + i;

  document.getElementsByClassName("properties-box")[0].scrollTo({
    top: $(id).offset().top + 50,
    left: 0,
    behavior: 'auto'
  })

  $(".search-box .search-card").removeClass("selected");
  $("#popup-recreation-"+i+" .search-card").addClass("selected");

  if (current_marker_index){
    this.hoverOff(current_marker_index);
  }
  this.hoverOn(index);

}


  showMapMarkers(){
    const { recreations, map, markers, locale, selected_property } = this.state;

    const selected_prop =  selected_property;


    markers.map( (e) => {
      e.remove();
    })

    var markers2 = [];

    var def_icon = L.icon({
      iconUrl: '/img/pin.png',

      iconSize:     [24, 24], // size of the icon
      //shadowSize:   [50, 64], // size of the shadow
      iconAnchor:   [12, 24], // point of the icon which will correspond to marker's location
      //shadowAnchor: [4, 62],  // the same for the shadow
      popupAnchor:  [-2, -28] // point from which the popup should open relative to the iconAnchor
    });


    var sel_icon = L.icon({
      iconUrl: '/img/pin_selected.png',

      iconSize:     [24, 24], // size of the icon
      //shadowSize:   [50, 64], // size of the shadow
      iconAnchor:   [12, 24], // point of the icon which will correspond to marker's location
      //shadowAnchor: [4, 62],  // the same for the shadow
      popupAnchor:  [-2, -28] // point from which the popup should open relative to the iconAnchor
    });

    var sel_prop;
    var sel_i;
    var sel_marker;


    const rec_with_markers = recreations.map((e, i) => {

      if(e.lat == null || e.lng == null){
        return e;
      }

      var marker = L.marker([e.lat, e.lng], {autoPan: false, autoPanSpeed: 0, opacity: 1, icon: def_icon});

        if(selected_property && selected_property == e.id){
          sel_marker = marker;
          sel_i = i;
          sel_prop = e;
        }

      if (!this.isMobile() ){
        const propertyPopup = this.getPropertyPopup(e, locale);



        marker.bindPopup(propertyPopup, {autoPan: false, minWidth: 200});
        marker.on("click", () => {
          this.scrollToSlide(e.id, i);
        })
      }else{
        marker.on("click", () => {
          this.navigateToSlide(i);
        })
      }

      marker.addTo(map);
      markers2.push(marker);
      e.marker = marker;
      return e
    });




    this.setState({markers: markers2, sel_icon: sel_icon, def_icon: def_icon, recreations: rec_with_markers}, function(){

      if(selected_prop && sel_prop){
        if (!this.isMobile() ){
          this.scrollToSlide(sel_prop.id, sel_i);
          sel_marker.openPopup();
        }else{
          this.navigateToSlide(sel_i);
        }
      }
    })
  }


initPriceSlider(){
  const { the_slider, min_price, max_price, price_from, price_to } = this.state;
  var slider = document.getElementById('map_range');
  var from_p;
  var to_p;
  var that = this;
  if (slider && !the_slider) {
    const the_slider2 = noUiSlider.create(slider, {
      start: [price_from, price_to],
      connect: true,
      step: 10,

      range: {
        'min': min_price,
        'max': max_price
      }
    });
    slider.noUiSlider.on('update', function (values, handle) {
      $("#map_price_from_s").html(Math.floor(slider.noUiSlider.get()[0]))
      $("#map_price_to_s").html(Math.floor(slider.noUiSlider.get()[1]))
    });
    slider.noUiSlider.on('end', function (values, handle) {
      from_p = Math.floor(slider.noUiSlider.get()[0])
      to_p = Math.floor(slider.noUiSlider.get()[1])

      that.setState({price_from: from_p, price_to: to_p}, () => {
         that.loadRecreations()
      });
    });

    this.setState({the_slider: the_slider2})
  }else{
    if (the_slider){
      the_slider.set([price_from, price_to]);
    }
  }
}

  initMap(id, lat, lng, zoom){
    const { map } = this.state;
    if(!map){
      const map2 = L.map(id,
        {zoomAnimation: true,
          tapTolerance: 30,
          fadeAnimation: true});

      map2.on('load', (e) => {
        console.log('map loaded');
        e.target._onResize();;
      });


      map2.setView([lat, lng], zoom);

      L.tileLayer( 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        subdomains: ['a','b','c'],
      }).addTo( map2 );
      map2.on('moveend', (e) => {
        this.loadRecreations();
      });
      this.setState({map: map2}, () => {
        this.loadRecreations();
      })
    }else{
      map._onResize();
      this.loadRecreations();
    }
  }


  changeAvailable(){
    const {
      only_available
    } = this.state;
    this.setState({only_available: !only_available} );
  }

  render () {
    const {
      recreations,
      locale,
      loading,
      selected_locations,
      selected_listing_options,
      selected_property_types,
      only_available,
      show_filter
    } = this.state;
    const {locations, listing_options, property_types} = this.props;
    const is_mobile = this.isMobile();

    const filters = selected_listing_options.length + selected_property_types.length;

    const filters_sel = filters > 0 ? " ["+filters+"]" : "";

    return (
      <React.Fragment>
        <button className="btn btn--fullwidth btn--filter show"
          onClick={() => {this.showModal()}}
          >
          {I18n.t('recreations.filter.show_on_map')}
        </button>

        <div className="modal bmap" id="mapModal" data-backdrop="true" tabIndex="-1" role="dialog1">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <button
                type="button"
                className="open-filters-btn"
                onClick={ () => { this.toggleFilter() } }
              ><span aria-hidden="true">{I18n.t('recreations.filter.filters') + filters_sel}</span></button>


            <button type="button" className="close close-btn" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
              <div className="modal-body">
                <div className='row map-view-row'>
                  <div className={['col-box col-md-2 filter-place', show_filter || !is_mobile ? '' : 'hidden'].join(' ')}>
                    <div className='mobile-header-filter'>
                      <div
                        onClick={ () => { this.toggleFilter() } }
                        className='close-mobile-filters'>&times;</div>
                      <a
                        href="#"
                        className='apply-filter'
                        onClick={ () => { this.toggleFilter(); return false; } }
                      >{I18n.t("recreations.filter.filter_button") + filters_sel}</a>
                    </div>
                    <div className='map-filters'>

                      {/*
                      <div className="map_filter__group">
                        <h5 className="w-medium">
                          {I18n.t("recreations.filter.date_range")}
                        </h5>
                      </div>

                      <div className="map_filter__group">
                        <h5 className="w-medium">
                          {I18n.t("recreations.filter.guests")}
                        </h5>
                      </div>
                      */}


                      <div className="map_filter__group">
                        <h5 className="w-medium">{I18n.t("recreations.filter.person_price")}</h5>
                        <p className="text-light">
                          <span id="map_price_from_s">65.00</span>
                          &nbsp;hrn
                          &mdash;
                          <span id="map_price_to_s">750.00</span>
                          &nbsp;hrn
                        </p>
                        <div className="form-group">
                          <div id="map_range" className='range'></div>
                        </div>
                      </div>

                      {/*
                      <div className="map_filter__group">

                        <label className={["checkbox", only_available ? "selected" : ''].join(' ')}>
                          {I18n.t("recreations.filter.only_available")}
                          <input
                            type="checkbox"
                            name="only_available"
                            id={"only_available"}
                            onChange ={() => this.changeAvailable()}
                            checked={only_available ? 'checked' : null}
                            value="1" />
                          <span className="checkmark"></span>
                        </label>

                      </div>
                      */}

                      <div className="map_filter__group">
                        <h5 className="w-medium">
                          {I18n.t("recreations.filter.property_type")}
                        </h5>

                        {property_types.map((e, i) => this.renderType.bind(this)(e, selected_property_types))}

                      </div>

                      <div className="map_filter__group">
                        <h5 className="w-medium">
                          {I18n.t("recreations.filter.property_options")}
                        </h5>

                        {listing_options.map((e, i) => this.renderOption.bind(this)(e, selected_listing_options))}

                      </div>



                    </div>
                  </div>
                  <div className='col-box col-md-4 properties-list'>
                      { loading && (
                      <div className='loader'>
                        <span>
                          {I18n.t("recreations.filter.loading")}
                        </span>
                      </div>
                      )}
                    <div className='properties-box'>
                    <div className='row'>
                      {recreations.map((e, i) => this.renderRecreation.bind(this)(e, locale, i))}

                    </div>
                  </div>
                </div>
                <div className='col-box col-md-6 map-place'>
                  <div id='map-box'>
                  </div>

                    <div className='slider-place'>
                      <div className='properties-slider'>
                        { recreations.length > 0 && (
                        recreations.map((e, i) => this.renderRecreationSlider.bind(this)(e, locale, i))
                        )
                        }
                        { recreations.length == 0 && (
                        <div

                          className="search-card-slider card--empty col"
                        >
                          <div className='search-card-inner'>
                            {I18n.t("recreations.not_found")}
                          </div>
                        </div>

                        )
                        }
                      </div>
                    </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
    );
  }
}

BrowseMap.propTypes = {
  visible: PropTypes.bool
};
export default BrowseMap
