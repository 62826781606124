import React from "react"
import PropTypes from "prop-types"
import moment from 'moment'
import GuestsFilterShowPage from "./properties/GuestsFilterShowPage"
import { DayPickerRangeController, DateRangePickerPhrases } from 'react-dates';
export default class AvailabilityCalendar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      focusedInput: "startDate",
      showCalendar: true,
      startDate: props.search_params.start_date ? moment(props.search_params.start_date) : null,
      endDate: props.search_params.end_date ? moment(props.search_params.end_date) : null,
      adults: props.search_params.guests ? Number(props.search_params.guests) : 2,
      children: props.search_params.children ? Number(props.search_params.children) : 0,
      roomsData: props.rooms_data,
      roomTitle: props.room_title,
      currency: props.currency,
      roomAvailabilities: [],
      calendarCurrentMonthDate: moment()
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.onNPClick = this.onNPClick.bind(this);
    this.onGuestsChange = this.onGuestsChange.bind(this);
    this.onDatesChange = this.onDatesChange.bind(this);
    this.clearDates = this.clearDates.bind(this);
    this.getMonths = this.getMonths.bind(this);
    this.reloadRooms = this.reloadRooms.bind(this);
    this.setUrl = this.setUrl.bind(this);
  }

  componentDidMount() {
    this.refreshCalendar();
  }

  onGuestsChange(a, c){
    a = a > 0 ? a : 1;
    a = a > 10 ? 10 : a;
    c = c >=0 ? c : 0;
    c = c > 10 ? 10 : c;
    this.setState({ adults: a, children: c }, ()=> {
      this.refreshCalendar();
      this.reloadRooms()
    });
  }

  clearDates(){
    this.onDatesChange({startDate: null, endDate: null});
    return false;
  }

  onDatesChange({ startDate, endDate }) {
    const oldStartDate  = this.state.startDate;


    this.setState({
      startDate: startDate,
      endDate: oldStartDate == startDate ? endDate : null,
    }, () => {
      if(startDate && endDate){
        this.reloadRooms()
      }
      if(!endDate){
        this.setState({roomsData: null})
      }
    });
  }

  daySize(){
    // const height = $("#calendarWidth").height();
    const documentWidth = $(".availability-block").width();
    const width = documentWidth;

    const months = this.getMonths();

    var fs;
    if(width > 350){
      fs = (width - 30) / (months * 7);
    }else{
      fs = width / (months * 7);
    }
    return fs;
  }

  renderDay(day) {
    const { roomAvailabilities } = this.state;
    const before = moment(day).isBefore(moment(), 'day');
    const formatedDate = day.format('YYYY-MM-DD');
    const filtered = roomAvailabilities.filter(room => room.date == formatedDate);
    const statuses = filtered.map(room => room.status);
    const countOfAvailableForEveryDay = statuses.filter(st => (st == 'available')).length

    var price = null;
    var price_int = 0;

    if (countOfAvailableForEveryDay > 0){
      price_int = parseInt(filtered[0].price)
      if (price_int >= 1000) {
        price = (price_int / 1000) + "K"
      }
    }


    var color = '';


    var bookingDayClass = '';



    var classNames = ['calendarDay', 'defaultDay', bookingDayClass]

    const dd = filtered ? filtered[0] : null;

    if (dd && !before && dd.first_available_after_booked){
      classNames.push('first_available_after_booked')
    }
    if (dd &&  !before && dd.first_booked_after_available){
      classNames.push('first_booked_after_available')
    }
    if (dd && !before && (dd.status === 'booked' || dd.status == 'sys_booked')){
      classNames.push('booked')
    }
    if (dd && !before && dd.status === 'available'){
      classNames.push('available')
    }


    return (
      <div
        className={classNames.join(" ")}
        style={{ background: color }}
        id={day.format('DD-MM')}
      >
        <span className='dayNumber'>
          {!before && countOfAvailableForEveryDay > 0 && (
          <>
          <b>{day.format('D')}</b>
          <br/>
          <small>{price_int > 0 ? price : ''}</small>
      </>
          )}
          {(before || countOfAvailableForEveryDay == 0) && (
      <s style={{color: "#999"}}>{day.format('D')}</s>
          )}
        </span>
    	</div>
    );
  }

  refreshCalendar(date = this.state.calendarCurrentMonthDate){
    // this.setState({ loading: true });
    const { property_id, locale, roomId } = this.props;
    const { adults } = this.state;
    var that = this;
    var params = { room_availability: {} };
    params['room_availability']['monthDate'] = date.format('DD-MM-YYYY');
    params['room_availability']['room_id'] = roomId;

    $.ajax({
      url: "/" + locale + "/recreations/" + property_id + "/room_availability?guests=" + adults,
      dataType: 'json',
      method: 'POST',
     beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      data: params,
      complete: (data) => that.setState({ roomAvailabilities: data.responseJSON })
    })
    // this.setState({ loading: false });
  }

  componentWillUnmount() {
  }

  informMessage(){
    const message = "<div class=\"roomItm__title inform-message\">\
      <span style=\"color: #b18915;\">" +
        I18n.t('front.recreations.show.dont_available_rooms') +
      "</span></div>";

    return message;
  }


  onNPClick(date){
    this.setState({calendarCurrentMonthDate: date});
    this.refreshCalendar(date);
  }

  onFocusChange(focusedInput) {
    this.setState({ focusedInput: !focusedInput ? "startDate" : focusedInput });
    this.setState({ roomsData: !focusedInput ? "startDate" : null });
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }


isDayBlocked(day){

  if (moment(day).isBefore(moment(), 'day')){
    return true;
  }

  const { roomAvailabilities } = this.state;

  const av = roomAvailabilities.find((el) => {
    return moment(day).isSame(moment(el["date"]), 'day')
  })
  return av == undefined;

}

  getMonths(){
    return $( window ).width() > 768 ? 2 : 1;
  }
  fill_hidden_fields(values){
    const { startDate, endDate, adults, children } = this.state;
    const form = $(`#${values.key}-about-block`).children(".simple_form");
    const submit = form.children("input[type=submit]");
    values.show ? submit.show() : submit.hide();
    if((startDate && endDate) && values.show ){
      form.children(".booking_order_booking_room_id").children("input").val(values.room_uid);
      form.children(".booking_order_start_date").children("input").val(`${startDate.format('DD-MM-YYYY')}`);
      form.children(".booking_order_end_date").children("input").val(`${endDate.format('DD-MM-YYYY')}`);
      form.children(".booking_order_children").children("input").val(children);
      form.children(".booking_order_guests").children("input").val(adults);
    }
  }

  setUrl(params){
    var url = `${window.location.href}?`;
    url = url.split("?")[0];

    const array_params = [];

    if(params.start_date){
      array_params.push(`date_range=${params.start_date}/${params.end_date}`);
    }
    if(params.guests){
      array_params.push(`guests=${params.guests}`);
    }
    if(params.children){
      array_params.push(`children=${params.children}`);
    }

    window.history.pushState({}, 'title', url + "?" + array_params.join("&"));
  }

  reloadRooms(){
    const { locale, search_params, url, property_id } = this.props;
    const { adults, children, startDate, endDate } = this.state;

    if(!startDate || !endDate){
      return;
    }

    const that = this;

    const params = { id: property_id };
    params.start_date = startDate.format('DD-MM-YYYY');
    params.end_date = endDate.format('DD-MM-YYYY');
    params.guests = adults;
    params.children = children;

    this.setUrl(params);

    $.ajax({
      url: url,
      dataType: 'json',
      method: 'GET',
      data: params,
      contentType: 'application/json',
      complete: (data) => (function() {
        const { rooms_data } = data.responseJSON;
        that.setState({roomsData: rooms_data});
      })()
    })
  }

  render () {
    const {roomsData,
      focusedInput,
      adults,
      children,
      startDate,
      endDate,
      currency,
    roomTitle } = this.state;

    const { roomId } = this.props;

    const duration = startDate && endDate ? Math.floor(moment.duration(endDate - startDate).asDays()) : 0


    return (
      <div ref={this.setWrapperRef}  className={`row`} >
        <div className='col-md-8 availability-block'>
          <div className="availability-panel">
            <div class='availability-calendar'>
              <DayPickerRangeController
              startDate={startDate}
              endDate={endDate}
              numberOfMonths={this.getMonths()}
              transitionDuration={0}
              onDatesChange={this.onDatesChange}
              noBorder={true}
              minimumNights={this.props.min_nights}
              onPrevMonthClick={this.onNPClick}
              onNextMonthClick={this.onNPClick}
              daySize={Math.round(this.daySize())}
              renderDayContents={(day) => (this.renderDay(day))}
              focusedInput={this.state.focusedInput}
              onFocusChange={this.onFocusChange}
              hideKeyboardShortcutsPanel={true}
              initialVisibleMonth={() => moment().add(0, "M")}
              isDayBlocked={(day) => this.isDayBlocked(day)}
            />

              </div>
            </div>
          </div>
          <div class='col-md-4'>
            <div className="show-booking-panel">
                  <h3>{roomTitle}</h3>
              <div className="guest-filters-on-show">
                <GuestsFilterShowPage
                adults={adults}
                children={children}
                onGuestsChange={this.onGuestsChange}
              />
                </div>
                <div className="dates-on-show">

                  {startDate &&

                  (<div><b>{I18n.t("recreations.filter.check_in_date")}</b><br/> {startDate.format("D MMM, dddd")}</div>)

                  }

                  {endDate &&

                  (
                  <div><b>{I18n.t("recreations.filter.check_out_date")}</b><br/> {endDate.format("D MMM, dddd")}</div>

                  )
                  }
                </div>
                  {startDate && endDate && (
                  <div className='clear-dates-link'>
                    <span className='clear-link' onClick={this.clearDates}>{I18n.t('recreations.filter.clear_dates')}</span>
                  </div>
                  )}

                <div className='dates-duration'>
                  {startDate && endDate && (
                  <span className='nights-selected'>
                    {duration > 0 && (
                    <React.Fragment>{I18n.t("recreations.filter.selected_nights", {count: duration})}</React.Fragment>
                    )}
                  </span>
                  )}
                </div>



                {roomsData && roomsData[this.props.roomId] && startDate && endDate && roomsData[this.props.roomId]['uid'] &&  (
                <div className='total-price-show'>
                  {roomsData[this.props.roomId]["price"] > 0 && (
                  <h2>{I18n.toCurrency(roomsData[this.props.roomId]["price"], {precision: 0, unit: currency})}</h2> )}
                    <form action={this.props.booking_url} method='post'>

                      <input type='hidden' name='authenticity_token' value={$('meta[name=csrf-token]').attr('content')}/>
                      <input type='hidden' name='booking_order[property_id]' value={this.props.property_id}/>
                      <input type='hidden' name='booking_order[booking_room_id]' value={roomsData[this.props.roomId]['uid']}/>
                      <input type='hidden' name='booking_order[guests]' value={adults}/>
                      <input type='hidden' name='booking_order[children]' value={children}/>
                      <input type='hidden' name='booking_order[start_date]' value={`${startDate.format('DD-MM-YYYY')}`}/>
                      <input type='hidden' name='booking_order[end_date]' value={`${endDate.format('DD-MM-YYYY')}`}/>
                      <input type='submit' className='roomItm__btn btn btn-success mb-2' value={I18n.t("recreations.booking_show.book")}/>
                    </form>
                  </div>
                  )}

              </div>
            </div>
          </div>
    )
  }
}

