import React from "react"
import filterIconBlue from 'images/2/img/_src/icons/filter_icon_blue.svg'

export default class ShowFilterButton extends React.Component {

  constructor(props) {
    super(props);

  }

  render () {
    const { changeShowFilters } = this.props;

    return (
      <a onClick={() => changeShowFilters(true)} className="show-filters-button">
        {I18n.t('recreations.filter.filters')}
      </a>
    )
  }
}
