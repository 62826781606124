import React from "react"
import PropTypes from "prop-types"
export default class FilterRegions extends React.Component {
  static defaultProps = {
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }
  render () {
    const {region, regions, locale} = this.props;

    const links = (
      <div aria-labelledby='regionSelect' className='dropdown-menu dropdown-menu-right'>
        {regions.map((r, index) => <a key={index} className='dropdown-item' href={'/' + (locale == 'uk' ? '' : (locale + '/')) + r['slug']} id={r['slug']}>{r.title}</a>)}
      </div>
    )
    return (

      <div className='regionSelect'>
        <a className="region-link-list" id="regionSelect" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" href="#">
          <span>{region}</span>
          <span className='region-select-arrow'></span>
        </a>
          {links}
      </div>
      )
  }
}
