import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';

export default class FacilityBaseFilter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showMore: false
    };
  }

  showMoreOptions(){
    this.setState({showMore: true});
  }

  render () {
    const { showMore } = this.state;
    const { facilities, onFacilitiesChange } = this.props;

    return (
      <div className="facility-base-filter border-b">
        <div className="row ml-0 mt-3">
          <h2>{I18n.t("recreations.filter.facilities_base")}</h2>
        </div>

        <div className="filter-panel">
          <div className={'filter-options'}>
            <FormControl component="fieldset" className={'options-list'}>
              <FormGroup>
                {showMore == false && facilities.base.slice(0, 5).map((item, index) => (
                  <FormControlLabel
                    control={<Checkbox checked={item["selected"]} onChange={onFacilitiesChange} name={"base_" + item["id"]} color={'default'} />}
                    label={item["title"]}
                    key={index}
                  />
                ))}

                {showMore && facilities.base.map((item, index) => (
                  <FormControlLabel
                    control={<Checkbox checked={item["selected"]} onChange={onFacilitiesChange} name={"base_" + item["id"]} color={'default'} />}
                    label={item["title"]}
                    key={index}
                  />
                ))}
              </FormGroup>
            </FormControl>

            {showMore == false && <h3>
              <a onClick={() => this.showMoreOptions()}>
                {I18n.t("recreations.form.show_more")}
              </a>
            </h3>}
          </div>
        </div>
      </div>
    )
  }
}
