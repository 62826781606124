import React from "react"
export default class GuestsFilter extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { onGuestsChange, adults, children } = this.props;

    return (
      <div className="guest-filter border-b-black">
        <div className="row ml-0 mt-3">
          <h2>{I18n.t('recreations.filter.guests')}</h2>
        </div>

        <div className="guests-row">
          <div className='guests-title'>
            {I18n.t("recreations.filter.adults")}
          </div>
          <div className='guests-controls'>
            <div
              className={`guests-control control-minus  ${adults == 1 ? 'disabled': ''}`}
              onClick={ () => { onGuestsChange(adults - 1, children) } }


            ></div>
            <div className='guests-number'>
              {adults}
            </div>
            <div
              className={`guests-control control-plus  ${adults >= 10 ? 'disabled': ''}`}
              onClick={ () => { onGuestsChange(adults + 1, children) } }
            >
            </div>
          </div>
        </div>

        <div className="guests-row">
          <div className='guests-title'>
            {I18n.t("recreations.filter.children")}
          </div>
          <div className='guests-controls'>
            <div
              className={`guests-control control-minus  ${children == 0 ? 'disabled': ''}`}
              onClick={ () => { onGuestsChange(adults, children - 1) } }


            ></div>
            <div className='guests-number'>
              {children}
            </div>
            <div
              className={`guests-control control-plus  ${children >= 10 ? 'disabled': ''}`}
              onClick={ () => { onGuestsChange(adults, children + 1) } }
            >
            </div>
          </div>
        </div>
      </div>
    )
  }
}
