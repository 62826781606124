import React from "react"

import { PhotoProvider, PhotoView } from 'react-photo-view';

export default class ReviewPhotos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render () {
    const {
      images
    } = this.props;
    return (
      <PhotoProvider  maskOpacity={0.5}>
        <div className="row mb-1">
          {images.map((item, index) => (
          <PhotoView key={index} src={item} width={100}>
            <div className='col-4 col-xl-2 mb-2'>
              <img src={item} alt="" className='img-thumbnail'/>
            </div>
          </PhotoView>
          ))}
        </div>
      </PhotoProvider>
      );
  }
}
