import React from "react"
import AirbnbCarousel from "react-airbnb-carousel"
export default class RoomCarusel extends React.Component {


  constructor(props) {
    super(props);
  }

  render () {
    const { imageUrls } = this.props;

    return (
      <AirbnbCarousel
        imageUrls={imageUrls}
        ratio={360 / 240}
        overlay={true}
      />
    )
  }
}
