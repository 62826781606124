import React from "react"
import ImageUploading from "react-images-uploading";


export default class NewReview extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      reviewable: this.props.reviewable,
      location: "0",
      clean: "0",
      service: "0",
      name: "",
      email: "",
      startDate: "",
      endDate: "",
      body: "",
      locationName: this.props.location_name,
      errors: {},
      creating: true,
      images: []
    };
  }

  setImages(images){
    this.setState({images: images})
  }


  onChange(imageList, addUpdateIndex){
    // data for submit
    this.setState({images: imageList})
  }

  changeCreating(value){
    this.setState({creating: value});
  }

  changeBody(value){
    this.setState({body: value});
    const { errors } = this.state;
    errors.body = null;
    this.setState({errors: errors});
  }

  changeStartDate(value){
    this.setState({startDate: value});
    const { errors } = this.state;
    errors.start_date = null;
    this.setState({errors: errors});
  }

  changeEndDate(value){
    this.setState({endDate: value});
    const { errors } = this.state;
    errors.end_date = null;
    this.setState({errors: errors});
  }

  changeLocation(value){
    this.setState({location: value});
    const { errors } = this.state;
    errors.location = null;
    this.setState({errors: errors});
  }

  changeClean(value){
    this.setState({clean: value});
    const { errors } = this.state;
    errors.clean = null;
    this.setState({errors: errors});
  }

  changeService(value){
    this.setState({service: value});
    const { errors } = this.state;
    errors.service = null;
    this.setState({errors: errors});
  }

  changeName(value){
    this.setState({name: value});
    const { errors } = this.state;
    errors.name = null;
    this.setState({errors: errors});
  }

  changeEmail(value){
    this.setState({email: value});
    const { errors } = this.state;
    errors.email = null;
    this.setState({errors: errors});
  }

  closeWindow(){
    this.setState({
      creating: true,
      location: "0",
      clean: "0",
      service: "0",
      name: "",
      email: "",
      startDate: "",
      endDate: "",
      body: ""
    });

    $('body').removeClass('no-scroll');
    $('.modal-review').removeClass('active');
  }

  wrongOrderDates(){
    const { startDate, endDate } = this.state;

    return new Date(startDate) > new Date(endDate);
  }

  valid(){
    var valid = true;
    const {
      location, clean, service, name, email, body, startDate, endDate, errors
    } = this.state;

    if(location == "0"){
      valid = false;
      errors.location = I18n.t("reviews.error.location");
    }

    if(clean == "0"){
      valid = false;
      errors.clean = I18n.t("reviews.error.clean");
    }

    if(service == "0"){
      valid = false;
      errors.service = I18n.t("reviews.error.service");
    }

    if(name == ""){
      valid = false;
      errors.name = I18n.t("reviews.error.blank");
    }

    if(body == ""){
      valid = false;
      errors.body = I18n.t("reviews.error.blank");
    }

    if(startDate == ""){
      valid = false;
      errors.start_date = I18n.t("reviews.error.blank");
    }

    if(endDate == ""){
      valid = false;
      errors.end_date = I18n.t("reviews.error.blank");
    }

    if(new Date(startDate) > new Date()){
      valid = false;
      errors.start_date = I18n.t("reviews.error.more_than_today");
    }

    if(new Date(endDate) > new Date()){
      valid = false;
      errors.end_date = I18n.t("reviews.error.more_than_today");
    }

    if(this.wrongOrderDates()){
      valid = false;
      errors.end_date = I18n.t("reviews.error.end_date");
      errors.start_date = I18n.t("reviews.error.start_date");
    }

    const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if(email == ""){
      valid = false;
      errors.email = I18n.t("reviews.error.blank");
    } else if(!reg.test(String(email).toLowerCase())){
      valid = false;
      errors.email = I18n.t("reviews.error.wrong_email");
    }

    this.setState({ errors: errors });

    return valid;
  }

  saveReview(){
    if(this.valid()){
      const {
        location, clean, service, name, email, body, reviewable, startDate, endDate, images
      } = this.state;
      const { locale, create_review_url_date } = this.props;
      const that = this;
      var params = {'review': {}};

      params.review.location = location;
      params.review.clean = clean;
      params.review.service = service;
      params.review.name = name;
      params.review.email = email;
      params.review.body = body;
      params.review.start_date = startDate;
      params.review.end_date = endDate;
      params.photos_data = []
      images.forEach(element => { params.photos_data.push(element.data_url) });

      params[create_review_url_date.key] = reviewable.id;

      $.ajax({
        url: create_review_url_date.url,
        dataType: 'json',
        method: 'POST',
        data: JSON.stringify(params),
        contentType: 'application/json',
        complete: (data) => (function() {
          const { errors, status } = data.responseJSON;

          if(status == "ok"){
            that.setState({creating: false});
          } else {
            that.setState({errors: errors});
          }
        })()
      })
    }
  }

  render () {
    const {
      location, clean, service, name, email, startDate, endDate, body, reviewable,
      locationName, errors, creating, images
    } = this.state;
    const maxNumber = 6;

    return (
      <div className="modal-review">
        <div className="leaveReview">

          <div className="leaveReview__title border-b">
            <h2>{I18n.t("front.property.add_comment")}</h2>
            <span className="leaveReview__desc">{`${reviewable.title} - ${locationName}`}</span>
            <a className="close-btn" onClick={() => this.closeWindow()}></a>
          </div>

          {creating && <>
            <div className="row leaveReview__ranges no-gutters">
              <div className="col-12 col-sm txt">
                <div className="range-group">
                  <label className="form-label">{I18n.t("front.property.location")}</label>
                  <ul className="breadcrumb empty_background">
                    <li>{location}</li>
                    <li>5</li>
                  </ul>
                </div>
                <input
                  type="range"
                  className="form-range"
                  id="range1"
                  min="0"
                  max="5"
                  step="1"
                  value={location}
                  onChange={(a) => this.changeLocation(a.target.value)}
                />
                {errors.location && <label className="form-label error-message">{errors.location}</label>}
              </div>

              <div className="col-12 col-sm txt">
                <div className="range-group">
                  <label className="form-label">{I18n.t("front.property.clean")}</label>
                  <ul className="breadcrumb empty_background">
                    <li>{clean}</li>
                    <li>5</li>
                  </ul>
                </div>
                <input
                  type="range"
                  className="form-range"
                  id="range2"
                  min="0"
                  max="5"
                  step="1"
                  value={clean}
                  onChange={(a) => this.changeClean(a.target.value)}
                />
                {errors.clean && <label className="form-label error-message">{errors.clean}</label>}
              </div>

              <div className="col-12 col-sm txt">
                <div className="range-group">
                  <label className="form-label">{I18n.t("front.property.service")}</label>
                  <ul className="breadcrumb empty_background">
                    <li>{service}</li>
                    <li>5</li>
                  </ul>
                </div>
                <input
                  type="range"
                  className="form-range"
                  id="range3"
                  min="0"
                  max="5"
                  step="1"
                  value={service}
                  onChange={(a) => this.changeService(a.target.value)}
                />
                {errors.service && <label className="form-label error-message">{errors.service}</label>}
              </div>
            </div>

            <div className="row leaveReview__inputs no-gutters">
              <div className="leaveReview__itm col-12 col-md-6">
                <label className="form-label">{I18n.t("front.property.client_name")}</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputEmail4"
                  value={name}
                  onChange={(a) => this.changeName(a.target.value)}
                />
                {errors.name && <label className="form-label error-message">{errors.name}</label>}
              </div>
              <div className="leaveReview__itm col-12 col-md-6">
                <label className="form-label">{I18n.t("front.property.email_address")}</label>
                <input
                  type="email"
                  className="form-control"
                  id="inputPassword4"
                  value={email}
                  onChange={(a) => this.changeEmail(a.target.value)}
                />
                {errors.email && <label className="form-label error-message">{errors.email}</label>}
              </div>
              <div className="col-12 leaveReview__itm-col">
                <div className="row">
                  <div className="col-6 col-md-6 leaveReview__itm-col">
                    <label className="form-label">{I18n.t("reviews.arrival")}</label>
                    <input
                      type="date"
                      className="form-control"
                      id="inputDate"
                      value={startDate}
                      onChange={(a) => this.changeStartDate(a.target.value)}
                    />
                    {errors.start_date && <label className="form-label error-message">{errors.start_date}</label>}

                  </div>
                  <div className="col-6 col-md-6 leaveReview__itm-col">
                    <label className="form-label">{I18n.t("reviews.departure")}</label>
                    <input
                      type="date"
                      className="form-control"
                      id="inputDate"
                      value={endDate}
                      onChange={(a) => this.changeEndDate(a.target.value)}
                    />
                    {errors.end_date && <label className="form-label error-message">{errors.end_date}</label>}
                  </div>
                </div>
              </div>
              <div className="col-12 leaveReview__itm-col">
                <label className="form-label">{I18n.t("front.property.your_comment")}</label>
                <textarea
                  className="form-control leaveReview__textarea"
                  id="leaveReview__textarea"
                  value={body}
                  onChange={(a) => this.changeBody(a.target.value)}
                >
                </textarea>
                {errors.body && <label className="form-label error-message">{errors.body}</label>}
              </div>

							<ImageUploading
							multiple
              value={images}
              onChange={(imageList, addUpdateIndex) => this.onChange(imageList, addUpdateIndex)}
              maxNumber={maxNumber}
              maxFileSize={5000000}
							dataURLKey="data_url"
							acceptType={["jpg", "png", "jpeg", "HEIC"]}
						>
								{({
							imageList,
							onImageUpload,
							onImageRemoveAll,
							onImageUpdate,
							onImageRemove,
							isDragging,
							dragProps
							}) => (
							// write your building UI
              <div className="upload__image-wrapper">
                <div className='upload_images_buttons'>
								<button
								style={isDragging ? { color: "red" } : null}
                onClick={onImageUpload}
                disabled={images.length == 6 ? 'disabled' : null}
								{...dragProps}
							>
                 {I18n.t("front.property.review.click_here_or_drop")}
							</button>
							&nbsp;
              <button onClick={onImageRemoveAll} disabled={images.length == 0 ? 'disabled' : null}>
                 {I18n.t("front.property.review.remove_all_photos")}
              </button>
            </div>
              <div className='review-selected-images'>
							{imageList.map((image, index) => (
							<div key={index} className="image-item">
								<img src={image.data_url} alt="" width="100" />
								<div className="image-item__btn-wrapper">
                  <button onClick={() => onImageUpdate(index)} className='fa fa-pen'>
                  </button>
                  <button onClick={() => onImageRemove(index)} className='fa fa-trash'>
                  </button>
								</div>
							</div>
              ))}
            </div>
						</div>
						)}
					</ImageUploading>


              <div className="col-auto leaveReview__btn">
                <button
                  type="submit"
                  className="btn btn-block btn-primary mb-5"
                  onClick={() => this.saveReview()}
                >
                  {I18n.t("front.property.add_comment")}
                </button>
              </div>
            </div>
          </>}

          {creating == false && <div className="row leaveReview__inputs no-gutters">
            <div className="col-12">
              <label className="label-success-message">{I18n.t("front.reviews.added")}</label>
            </div>
            <div className="col-12 leaveReview__btn">
              <button
                type="submit"
                className="btn btn-block btn-primary mb-5"
                onClick={() => this.closeWindow()}
              >
                {I18n.t("actions.close")}
              </button>
            </div>
          </div>}

        </div>
      </div>
    )
  }
}
