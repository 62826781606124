import React from "react"
import PropTypes from "prop-types"
import FilterRegions from "./mobileVersion/FilterRegions"
// import SearchTerm from "./SearchTerm"
import AccessibilityFilter from "./AccessibilityFilter"
import ShowMapButton from "./mobileVersion/ShowMapButton"
import MapMobile from "./mobileVersion/MapMobile"
import ShowFilterButton from "./mobileVersion/ShowFilterButton"
import ModalMobileFilters from "./mobileVersion/ModalMobileFilters"

export default class SearchPropertiesFilterMobile extends React.Component {
  static defaultProps = {
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: false

    };
  }
  render () {
    const {
      startDate, endDate, onDatesChange,
      regions, region,
      locale, q, onQChange,
      adults, children, onGuestsChange,
      propertyTypes, onPropertyTypeChange,
      roomTypes, onRoomTypesChange,
      comfort, onComfortChange, filters, onFiltersChange,
      facilities, onFacilitiesChange,
      locations, location, onLocationsChange,
      activities, onActivitiesChange,
      clearSection, minPrice, maxPrice, onPriceChange,
      onlyAvailableRooms, onOnlyAvailableRoomsChange,
      showMap, isShowMap, showFilters, changeShowFilters,
      startMinPrice, startMaxPrice, showRoomTypes,
      disableOnlyAvailableFilter, loadRecreations,
      lat, lng, loading, hoverOn, hoverOff,  setMap, zoom, map, recreations
    } = this.props;
    return (
      <>
        <div className='recreations-filter'>
          <div className='row'>
            <div className='col-12 search-filters'>
              <ShowFilterButton
                changeShowFilters={changeShowFilters}
              />
              {<ShowMapButton showMap={showMap}/>}
            </div>
            <div className='col-12'>
              <AccessibilityFilter
                onlyAvailableRooms={onlyAvailableRooms}
                onOnlyAvailableRoomsChange={onOnlyAvailableRoomsChange}
                disableOnlyAvailableFilter={disableOnlyAvailableFilter}
              />
            </div>
          </div>
        </div>
        {isShowMap && <>
          <div className='mobileMapBox'>
            <div className='mapControlPanel'>
              <div className='row'>
                <div className='col-6'>
                  <AccessibilityFilter
                  onlyAvailableRooms={onlyAvailableRooms}
                  onOnlyAvailableRoomsChange={onOnlyAvailableRoomsChange}
                  disableOnlyAvailableFilter={disableOnlyAvailableFilter}
                />
                  </div>
                <div className='col-4'>
                  <ShowFilterButton
                  changeShowFilters={changeShowFilters}
                />
                  </div>
                  <div className='col-2'>
                  {<ShowMapButton isShowMap = {isShowMap} showMap={showMap}/>}
                </div>
              </div>
            </div>
                <MapMobile
                recreations={recreations}
                region={region}
                locale={locale}
                map={map}
                setMap={setMap}
                loadRecreations={loadRecreations}
                loading={loading}
                lat={lat}
                lng={lng}
                zoom={zoom}
                hoverOn={hoverOn}
                hoverOff={hoverOff}
              />
          </div>
          </>}
        <ModalMobileFilters
          showFilters={showFilters}
          changeShowFilters={changeShowFilters}
          adults={adults}
          children={children}
          onGuestsChange={onGuestsChange}
          propertyTypes={propertyTypes}
          onPropertyTypeChange={onPropertyTypeChange}
          filters={filters}
          onFiltersChange={onFiltersChange}
          minPrice={minPrice}
          maxPrice={maxPrice}
          onPriceChange={onPriceChange}
          roomTypes={roomTypes}
          showRoomTypes={showRoomTypes}
          onRoomTypesChange={onRoomTypesChange}
          comfort={comfort}
          onComfortChange={onComfortChange}
          locations={locations}
          location={location}
          onLocationsChange={onLocationsChange}
          facilities={facilities}
          onFacilitiesChange={onFacilitiesChange}
          startDate={startDate}
          endDate={endDate}
          onDatesChange={onDatesChange}
          activities={activities}
          onActivitiesChange={onActivitiesChange}
          clearSection={clearSection}
          startMinPrice={startMinPrice}
          startMaxPrice={startMaxPrice}
          loadRecreations={loadRecreations}
          q={q}
          onQChange={onQChange}
        />
      </>
    )
  }
}
