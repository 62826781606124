import React from "react"
import filterIconBlue from 'images/2/img/_src/icons/filter_icon_blue.svg'
import mapIconWhite from 'images/2/img/_src/icons/map_icon_white.svg'
import ModalMobileFilters from "./places/ModalMobileFilters"

export default class PlaceIndexFilters extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      placeTypes: props.place_types,
      locale: props.locale,
      showFilters: false,
    };
  }

  changeShowFilters(value){
    this.setState({showFilters: value});
  }

  checkActive(type){
    const arrPath = $(location).attr('href').split("/");
    const slug = arrPath[arrPath.length-1];
    return slug == type ? "active" : ""
  }

  render () {
    const { placeTypes, locale, locations, showFilters } = this.state;

    return (
      <div className="container">
        <div className="titleBlock mb-gutter">
          <div className="titleBlock__title regionSelect">


            <ul className="d-none d-md-flex titleBlock__menu">
              <li key="allPlaces">
                <a className={this.checkActive("places")} href={'/' + locale + '/places'}>
                  {I18n.t("front.places.all_places")}
                </a>
              </li>
              {placeTypes.map((type, index) => (
                <li key={index}>
                  <a className={this.checkActive(type.slug)} href={type.url}>{type.title}</a>
                </li>
              ))}
            </ul>
          </div>

          <div className="details__info titleBlock__info">
            <ul className="titleBlock__nav">
              <li className="d-inline d-md-none">
                <a className="btn btn-primary" onClick={() => this.changeShowFilters(true)}>
                  <img src={filterIconBlue} alt="filter"/>
                </a>
              </li>
              {false && <li>
                <a className="btn btn-primary" href="#">
                  <img
                    className="map-img"
                    src={mapIconWhite}
                    alt="map"
                  />
                  Show map
                </a>
              </li>}
            </ul>
          </div>
        </div>
        <ModalMobileFilters
          showFilters={showFilters}
          changeShowFilters={(value) => this.changeShowFilters(value)}
          placeTypes={placeTypes}
          locale={locale}
        />
      </div>
    )
  }
}
