import React from "react"
import FloatSidebar from 'float-sidebar';
import ReactPaginate from 'react-paginate';
import Recreation from "./Recreation"

export default class MapDesktop extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      lat: props.lat,
      lng: props.lng,
      zoom: props.zoom,
      markers: [],
      locale: props.locale,
      selected_property: null,
      sel_icon: null,
      def_icon: null,
    }
  }

  handlePageClick(data){
    const { changePage } = this.props;
    changePage(data.selected + 1);
  };

  componentDidMount(){
    const that = this;
    this.initMap('map-box');
    //var stickyMap = $('.js-sticky-map').sticksy({ topSpacing: 0, listen: false });
    //console.log('stick')
    const sidebar  = document.querySelector('.map-place');
    const relative = document.querySelector('.map-wrapper');

    const floatSidebar = FloatSidebar({
        sidebar,
        relative,
        topSpacing: 0,
        bottomSpacing: 0
    });


  }

  initMap(id){
    const { lat, lng, zoom } = this.state;
    const { map, loadRecreations, setMap } = this.props;
    if(!map){
      $("#map-box").css('min-height', "100vh");
      $("#map-box").css('width', '100%');
      $(".map-place").css('width', parseInt($('.map-wrapper').css('width')) / 3 - 20);
      const map2 = L.map(id,
        {zoomAnimation: true,
          tapTolerance: 30,
          doubleClickZoom: true,
          scrollWheelZoom: false,
          fadeAnimation: true});

      map2.on('load', (e) => {
        console.log('map loaded');
        e.target._onResize();;
      });


      map2.setView([lat, lng], zoom);

      const that = this;

      L.tileLayer( 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        subdomains: ['a','b','c'],
      }).addTo( map2 );
      map2.on('moveend', (e) => {
        console.log('moved');
        const center = map2.getCenter();
        setMap(map2, center.lat, center.lng, map2.getZoom());
        loadRecreations({ page: 1, setUrl: true }, function(count){
          that.adjustHeight(count);
        });
        $(".map-place").css('width', (parseInt($('.map-wrapper').css('width')) / 3 - 20) + 'px');
      });
      setMap(map2, lat, lng, zoom, function(){
        loadRecreations({ page: 1, setUrl: true }, function(count){
          that.adjustHeight(count)
        });
        $(".map-place").css('width', (parseInt($('.map-wrapper').css('width')) / 3 - 20) + 'px');
      })
    }else{
      map._onResize();
      loadRecreations({ page: 1, setUrl: true })
    }
  }

  adjustHeight(count){
    const elH = parseInt($(".propertyItem").css("height"));
    $(".map-place").css('height', (count / 2 + 1) * elH + 'px');
  }

  render () {
    const { recreations, region, countPage, page, loading, hoverOn, hoverOff, markRecreation } = this.props;

    return (
      <div id="properties" className={loading ? "not-clickable-f" : ""}>
        <div className="row map-wrapper">
          <div className="col-8 col-md-8 map-properties">
            <div className="row">
              {recreations && recreations.map((recreation, index) => (
              <div className="col-4 col-md-4 col-lg-4 mt-1 propertyItem"
              id={`map-recreation-${recreation.id}`}
              onMouseEnter ={() => hoverOn(recreation.id)}
              onMouseLeave ={() => hoverOff(recreation.id)}
              key={index}
            >
                <Recreation
                recreation={recreation}
                region={region}
                loading={loading}
                markRecreation={markRecreation}
              />
                </div>
                ))}
              </div>
            </div>
            <div className="col-4 col-md-4">
              <div className='js-sticky-map map-place'>
                <div id='map-box'></div>
              </div>
            </div>
          </div>
        </div>
        )
}
}

