import React from "react"
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';

const BlackRadio = withStyles({
  root: {
    color: (0, 0, 0, 0.54),
    '&$checked': {
      color: (0, 0, 0, 0.54),
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);


export default class ModalMobileFilters extends React.Component {

  constructor(props) {
    super(props);
  }

  closeWindow(){
    const { changeShowFilters } = this.props;

    changeShowFilters(false);
  }

  handleChange(value){
    const { locale, region } = this.props;
    const slug = value == "services" ? "/services" : `/services/${value}`;

    window.location.href = '/' + locale + '/' + region.slug + slug;
  }

  currentType(){
    const arrPath = $(location).attr('href').split("/");
    return arrPath[arrPath.length-1];
  }

  render () {
    const { showFilters, serviceTypes } = this.props;

    return (
      <div className={`${showFilters ? "active" : ""} modal-services-filters`}>
        <div className="leaveReview">
          <div className="leaveReview__title border-b">
            <h2>{I18n.t('recreations.filter.filters')}</h2>
            <a className="close-btn" onClick={() => this.closeWindow()}></a>
          </div>

          <div className="property-types-filter border-b">
            <div className="row ml-0 mt-3">
              <h2>{I18n.t("recreations.filter.place_types")}</h2>
            </div>

            <div className={'filter-options'}>
              <FormControl component="fieldset" className={'options-list'}>
                <RadioGroup aria-label="gender" name="gender1" value={this.currentType()} onChange={(e) => this.handleChange(e.target.value)}>
                  <FormControlLabel value="services" control={<BlackRadio />} label={I18n.t("front.services.all_services")} />
                  {serviceTypes.map((type, index) => (
                    <FormControlLabel value={type.slug} control={<BlackRadio />} label={type.title} key={index}/>
                  ))}
                </RadioGroup>
              </FormControl>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
