import React from "react"

export default class ClearDates extends React.Component {

  constructor(props) {
    super(props);
    this.clear = this.clear.bind(this);
  }

  clear(event){
    const { clearSection } = this.props;
    clearSection();
    event.preventDefault();
  }

  render () {
    return (
      <div className="filter-panel clear-dates border-b">
        <div className='row panel-footer show-calendar'>
          <div className='col-12'>
            <a href="#" onClick={this.clear} className="as-link">{I18n.t('recreations.filter.clear_dates')}</a>
          </div>
        </div>
      </div>
    )
  }
}
