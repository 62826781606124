import React from "react"
import PropTypes from "prop-types"
export default class SearchTerm extends React.Component {
  static defaultProps = {
  };

  constructor(props) {
    super(props);
  }

  applyFilter(){
    const {loadRecreations, closeWindow } = this.props;

    loadRecreations({ page: 1, setUrl: true });
    closeWindow();
  }
  render () {
    const { q, onQChange } = this.props;

    return (
      <div className="price-filter border-b mt-3">
        <div className="input-group col-md-4 pb-3 pl-0 pr-0">
          <input
            className="form-control py-2"
            type="search"
            value={q}
            id="example-search-input"
            onChange={onQChange}
            placeholder={I18n.t("recreations.filter.search_placeholder")}
          />
          <span className="input-group-append">
            <button
              className="btn btn-outline-secondary button-search"
              type="button"
              onClick={() => this.applyFilter()}
            >
              <i className="fa fa-search"></i>
            </button>
          </span>
        </div>
      </div>
    )
  }
}
