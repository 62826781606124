import React from "react"
import AirbnbCarousel from "react-airbnb-carousel"
export default class PhotoCarusel extends React.Component {


  constructor(props) {
    super(props);
  }

  render () {
    const { imageUrls } = this.props;

    return (
      <AirbnbCarousel
        imageUrls={imageUrls}
        ratio={520 / 423}
        overlay={false}
      />
    )
  }
}

