import React from "react"
import caretRight from 'images/booking/caret-right.svg'
import moment from 'moment'
import { DayPickerRangeController } from 'react-dates';

export default class Calendar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      focusedInput: "startDate",
    };
    this.onFocusChange = this.onFocusChange.bind(this);
  }

  onFocusChange(focusedInput) {
    this.setState({ focusedInput: !focusedInput ? "startDate" : focusedInput });
  }

  isDayBlocked(day){
    return moment(day).isBefore(moment(), 'day');
  }

  render () {
    const { focusedInput } = this.state;
    const { startDate, endDate, openCalendar, onDatesChange } = this.props;

    const duration = startDate && endDate ? Math.floor(moment.duration(endDate - startDate).asDays()) : 1

    return (
      <div className="calendar-filter" id="calendar_filter">
        <div className="row ml-0 mt-3  border-b pb-4">
          <div className="col-10">
            <div className="row">
              <h2>
                { startDate && endDate ? I18n.t("recreations.filter.selected_nights", {count: duration}) : I18n.t("recreations.filter.select_date")}
              </h2>
            </div>
            <div className="row">
              {startDate && startDate.format("D MMM, dddd")}
              {endDate && (<React.Fragment>&nbsp;―&nbsp;</React.Fragment>) }
              {endDate && endDate.format("D MMM, dddd")}
            </div>
          </div>
          <div className="col-2">
            <a className="close-btn close-btn-calendar" onClick={() => openCalendar(false)}></a>
          </div>
        </div>
        <div className='row'>
          <div className='col-12'>
            <DayPickerRangeController
              startDate={startDate}
              endDate={endDate}
              numberOfMonths={2}
              transitionDuration={0}
              onDatesChange={onDatesChange}
              focusedInput={focusedInput}
              onFocusChange={this.onFocusChange}
              noBorder={true}
              hideKeyboardShortcutsPanel={true}
              orientation="vertical"
              isDayBlocked={(day) => this.isDayBlocked(day)}
            />
          </div>
        </div>
      </div>
    )
  }
}
