import React from "react"
export default class GuestsFilter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showFilter: false,
    };
    this.toggleFilter = this.toggleFilter.bind(this);
    this.clear = this.clear.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ showFilter: false });
    }
  }

  toggleFilter(){
    const { showFilter } = this.state;
    this.setState({ showFilter: !showFilter });
  }

  clear(){
    const { onGuestsChange } = this.props;
    onGuestsChange(1, 0);
  }

  applyFilter(){
    const { loadRecreations } = this.props;
    this.toggleFilter();
    loadRecreations({ page: 1, setUrl: true });
  }

  render () {
    const { showFilter } = this.state;
    const { onGuestsChange, adults, children } = this.props;

    return (
      <div ref={this.setWrapperRef} className={`filter-block  ${showFilter ? 'selected': ''}`} >

        <span onClick={this.toggleFilter} className={'filter-title-span'} >
          <React.Fragment>
            {I18n.t("recreations.filter.guests")}:&nbsp;
            {adults}
            {children > 0 && (
            <React.Fragment>
              +{children}
            </React.Fragment>
            )}
          </React.Fragment>
        </span>


        {showFilter &&

        <div className="filter-panel">

          <div className="guests-row">
            <div className='guests-title'>
              {I18n.t("recreations.filter.adults")}
            </div>
            <div className='guests-controls'>
              <div
                className={`guests-control control-minus  ${adults == 1 ? 'disabled': ''}`}
                onClick={ () => { onGuestsChange(adults - 1, children) } }


              ></div>
              <div className='guests-number'>
                {adults}
              </div>
              <div
                className={`guests-control control-plus  ${adults >= 10 ? 'disabled': ''}`}
                onClick={ () => { onGuestsChange(adults + 1, children) } }
              >
              </div>
            </div>
          </div>

          <div className="guests-row">
            <div className='guests-title'>
              {I18n.t("recreations.filter.children")}
            </div>
            <div className='guests-controls'>
              <div
                className={`guests-control control-minus  ${children == 0 ? 'disabled': ''}`}
                onClick={ () => { onGuestsChange(adults, children - 1) } }


              ></div>
              <div className='guests-number'>
                {children}
              </div>
              <div
                className={`guests-control control-plus  ${children >= 10 ? 'disabled': ''}`}
                onClick={ () => { onGuestsChange(adults, children + 1) } }
              >
              </div>
            </div>
          </div>


          <div className='row panel-footer'>
            <div className='col-6'>
              <a href="#" className='clear-link' onClick={this.clear}>{I18n.t('recreations.filter.clear')}</a>
            </div>

            <div className='col-6'>
              <a href="#" onClick={this.applyFilter} className='btn btn-primary apply-filter-btn'>{I18n.t('recreations.filter.apply')}</a>
            </div>

          </div>
        </div>
        }
      </div>
      )
  }
}
