import React from "react"

export default class BookService extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      comment: "",
      date: "",
      phone: "",
      errors: {},
      creating: true
    };
  }

  changeCreating(value){
    this.setState({creating: value});
  }

  changeDate(value){
    this.setState({date: value});
    const { errors } = this.state;
    errors.date = null;
    this.setState({errors: errors});
  }

  changeComment(value){
    this.setState({comment: value});
    const { errors } = this.state;
    errors.comment = null;
    this.setState({errors: errors});
  }

  changePhone(value){
    this.setState({phone: value});
    const { errors } = this.state;
    errors.phone = null;
    this.setState({errors: errors});
  }

  changeName(value){
    this.setState({name: value});
    const { errors } = this.state;
    errors.name = null;
    this.setState({errors: errors});
  }

  changeEmail(value){
    this.setState({email: value});
    const { errors } = this.state;
    errors.email = null;
    this.setState({errors: errors});
  }

  valid(){
    var valid = true;
    const { name, date, phone, errors } = this.state;

    if(name == ""){
      valid = false;
      errors.name = I18n.t("reviews.error.blank");
    }

    if(date == ""){
      valid = false;
      errors.date = I18n.t("reviews.error.blank");
    }

    if(new Date(date) < new Date()){
      valid = false;
      errors.date = I18n.t("reviews.error.need_more_than_today");
    }

    if(phone == ""){
      valid = false;
      errors.phone = I18n.t("reviews.error.blank");
    }

    this.setState({ errors: errors });

    return valid;
  }

  createBook(){
    if(this.valid()){
      const { name, email, comment, date, phone } = this.state;

      const { id, url } = this.props;
      const that = this;
      var params = {'service_request': {}};

      params.service_request.phone = phone;
      params.service_request.date = date;
      params.service_request.name = name;
      params.service_request.email = email;
      params.service_request.comment = comment;

      params.service_id = id;

      $.ajax({
        url: url,
        dataType: 'json',
        method: 'POST',
        data: JSON.stringify(params),
        contentType: 'application/json',
        complete: (data) => (function() {
          const { errors, status } = data.responseJSON;

          if(status == "ok"){
            that.setState({creating: false});
          } else {
            that.setState({errors: errors});
          }
        })()
      })
    }
  }

  dayToday(){
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    const yyyy = today.getFullYear();

    return yyyy + "-" + mm + "-" + dd;
  }

  componentDidMount() {
    const resizeObserver = new ResizeObserver((entries) => {
      const sizeDocument = entries[0].target.scrollWidth;
      //if(sizeDocument > 1244){
      //  $("#get-width").width(250).css('margin','0px');
      //} else {
      //  const size = $("#reviews").width();
      //  $("#get-width").width(size-40).css('margin','10px');
      //}
    });

    resizeObserver.observe(document.body);
  }

  render () {
    const { name, email, date, phone, comment, errors, creating } = this.state;

    return (
      // <aside className="d-none d-xl-block">
      <aside className="d-xl-block">
        <div name="bookService" className="bookService" id="get-width">
          <h2>{I18n.t("front.service_request.book_service")}</h2>

          {creating && <>
            <div className="bookService__itm">
              <label className="form-label">{I18n.t("front.service_request.name")}</label>
              <input
                type="text"
                className="form-control"
                id="inputEmail"
                value={name}
                onChange={(a) => this.changeName(a.target.value)}
              />
              {errors.name && <label className="form-label error-message">{errors.name}</label>}
            </div>
            <div className="bookService__itm">
              <label className="form-label">{I18n.t("front.service_request.date")}</label>
              <input
                type="date"
                className="form-control"
                id="inputDate"
                value={date}
                onChange={(a) => this.changeDate(a.target.value)}
                min={this.dayToday()}
              />
              {errors.date && <label className="form-label error-message">{errors.date}</label>}
            </div>
            <div className="bookService__itm">
              <label className="form-label">{I18n.t("front.service_request.phone")}</label>
              <input
                type="tel"
                className="form-control"
                id="bookServicePhone"
                className="form-control"
                value={phone}
                onChange={(a) => this.changePhone(a.target.value)}
              />
              {errors.phone && <label className="form-label error-message">{errors.phone}</label>}
            </div>
            <div className="bookService__itm">
              <label className="form-label">{I18n.t("front.service_request.email")}</label>
              <input
                type="email"
                className="form-control"
                id="bookEmailId"
                value={email}
                onChange={(a) => this.changeEmail(a.target.value)}
              />
            </div>
            <div className="bookService__itm">
              <label className="form-label">{I18n.t("front.service_request.comment")}</label>
              <textarea
                className="form-control bookService__textarea"
                id="bookServiceComment"
                value={comment}
                onChange={(a) => this.changeComment(a.target.value)}
              ></textarea>
            </div>

            <div className="leaveReview__btn">
              <button
                type="submit"
                className="btn btn-block btn-success mb-3"
                onClick={() => this.createBook()}
              >
                {I18n.t("front.service_request.create_book_service")}
              </button>
            </div>
          </>}

          {creating == false && <div className="row leaveReview__inputs no-gutters">
            <div className="col-12">
              <label className="label-success-message">{I18n.t("front.service_request.success_message")}</label>
            </div>
          </div>}

        </div>
      </aside>
    )
  }
}
