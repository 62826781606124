import React from "react"
import GuestsFilter from "./GuestsFilter"
import PlaceTypeFilter from "./PlaceTypeFilter"
import PriceFilter from "./PriceFilter"
import RoomTypeFilter from "./RoomTypeFilter"
import ComfortFilter from "./ComfortFilter"
import ActivityFilter from "./ActivityFilter"
import LocationFilter from "./LocationFilter"
import GeneralFilter from "./GeneralFilter"
import FacilityRoomFilter from "./FacilityRoomFilter"
import FacilityBaseFilter from "./FacilityBaseFilter"
import DateLabel from "./DateLabel"
import Calendar from "./Calendar"
import ClearApplyButtons from "./ClearApplyButtons"
import ClearDates from "./ClearDates"
import SearchTerm from "./SearchTerm"

export default class ModalMobileFilters extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showCalendar: false,
    };

    this.openCalendar = this.openCalendar.bind(this);
  }

  openCalendar(value){
    this.setState({showCalendar: value});
  }

  closeWindow(){
    const { changeShowFilters } = this.props;

    changeShowFilters(false);
  }

  render () {
    const { showCalendar } = this.state;
    const {
      showFilters, adults, children, onGuestsChange, propertyTypes, onPropertyTypeChange,
      minPrice, maxPrice, onPriceChange, roomTypes, onRoomTypesChange, comfort,
      onComfortChange, locations, location, onLocationsChange, facilities, onFacilitiesChange,
      startDate, onDatesChange, endDate, activities, onActivitiesChange, clearSection,
      startMinPrice, startMaxPrice, loadRecreations, q, onQChange, showRoomTypes,
      filters, onFiltersChange
    } = this.props;

    return (
      <div className={`${showFilters ? "active" : ""} modal-review`}>
        {showCalendar == false && <div className="leaveReview">
          <div className="leaveReview__title border-b">
            <h2>{I18n.t('recreations.filter.filters')}</h2>
            <a className="close-btn" onClick={() => this.closeWindow()}></a>
          </div>

          <DateLabel
            startDate={startDate}
            endDate={endDate}
            openCalendar={this.openCalendar}
          />
          {(startDate || endDate) && <ClearDates
            clearSection={() => clearSection('clearDates')}
          />}
          <GuestsFilter
            adults={adults}
            children={children}
            onGuestsChange={onGuestsChange}
          />
          <SearchTerm
            q={q}
            onQChange={onQChange}
            loadRecreations={loadRecreations}
            closeWindow={() => this.closeWindow()}
          />
          <PriceFilter
            minPrice={minPrice}
            maxPrice={maxPrice}
            onPriceChange={onPriceChange}
            startMinPrice={startMinPrice}
            startMaxPrice={startMaxPrice}
          />


              {filters.map((item, index) => (
              <GeneralFilter
                options={item['options']}
                onOptionChange={onFiltersChange}
                section={item['section']}
                title={item['title']}
              />
                ))}

          <LocationFilter
            locations={locations}
            location={location}
            onLocationsChange={onLocationsChange}
          />
          <ClearApplyButtons
            clearSection={() => clearSection('all')}
            loadRecreations={loadRecreations}
            closeWindow={() => this.closeWindow()}
          />
        </div>}

        {showCalendar && <div className="leaveReview calendar">
          <Calendar
            startDate={startDate}
            endDate={endDate}
            openCalendar={this.openCalendar}
            onDatesChange={onDatesChange}
          />
        </div>}
      </div>
    )
  }
}
