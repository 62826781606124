import React from "react"
export default class GuestsFilterShowPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showFilter: true,
    };
    this.toggleFilter = this.toggleFilter.bind(this);
    this.clear = this.clear.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ showFilter: true });
    }
  }

  toggleFilter(){
    const { showFilter } = this.state;
    this.setState({ showFilter: !showFilter });
  }

  clear(){
    const { onGuestsChange } = this.props;
    onGuestsChange(1, 0);
  }

  applyFilter(){
    this.toggleFilter();
  }

  render () {
    const { showFilter } = this.state;
    const { onGuestsChange, adults, children } = this.props;

    return (
      <div ref={this.setWrapperRef} className={`filter-block  ${showFilter ? 'selected': ''}`} >
        {showFilter &&
          <div className="filter-panel">
            <div className="guests-row">
              <div className='guests-title'>
                {I18n.t("recreations.filter.adults")}
              </div>
              <div className='guests-controls'>
                <div
                  className={`guests-control control-minus  ${adults == 1 ? 'disabled': ''}`}
                  onClick={ () => { onGuestsChange(adults - 1, children) } }
                ></div>
                <div className='guests-number'>
                  {adults}
                </div>
                <div
                  className={`guests-control control-plus  ${adults >= 10 ? 'disabled': ''}`}
                  onClick={ () => { onGuestsChange(adults + 1, children) } }
                ></div>
              </div>
            </div>

            {false && (
            <div className="guests-row">
              <div className='guests-title'>
                {I18n.t("recreations.filter.children")}
              </div>
              <div className='guests-controls'>
                <div
                  className={`guests-control control-minus  ${children == 0 ? 'disabled': ''}`}
                  onClick={ () => { onGuestsChange(adults, children - 1) } }
                ></div>
                <div className='guests-number'>
                  {children}
                </div>
                <div
                  className={`guests-control control-plus  ${children >= 10 ? 'disabled': ''}`}
                  onClick={ () => { onGuestsChange(adults, children + 1) } }
                ></div>
              </div>
            </div>
            )}
          </div>
        }
      </div>
    )
  }
}
