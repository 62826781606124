import React from "react"
import CircularProgress from '@material-ui/core/CircularProgress';
import PhotoCarusel from "../PhotoCarusel"
import emptyHeart from 'images/empty_heart.svg'
import redHeart from 'images/red_heart.svg'

export default class Recreation extends React.Component {

  constructor(props) {
    super(props);
  }

  pathWithParams(){
    const url = window.location.href;
    const { recreation } = this.props;
    var params = url.split("?")[1];
    params = params == null ? [] : params.split("&");
    const additionlParams = [];
    params.map(param => {
      if((param.includes("guests") || param.includes("children")) || param.includes("date_range")){
        additionlParams.push(param);
      }
    });

    return recreation.path_to_property + "?" + additionlParams.join("&");
  }

  render () {
    const { recreation, region, loading, markRecreation } = this.props;
    const search_data = JSON.parse(recreation.search_data);
    const fullUrl = this.pathWithParams();

    return (
      <div>
          <div className="imgHolder">
            {!loading && search_data && search_data["photos"]  &&
            <PhotoCarusel
            imageUrls={search_data["photos"]}
            />}
            {recreation.hot && <span className="hot-box">💯</span>}
            {search_data && search_data.slots &&
            <div className="slots-box">

              {search_data.slots.map((slot, index) => (
              <div className='slot'>{slot}</div>
              ))}
            </div>

            }
            {loading && <div className="loader-center"><CircularProgress/></div>}
          </div>
        {recreation.blank == null &&
          <div className={`${recreation.hot ? "discount-hot" : ""} ${loading ? "transparent-color" : ""} pt-1 pl-1 pr-1`}>
            <a href={fullUrl} target="_blank">
             <div className="row region-location-rating mr-0 ml-0">
              <div className="col-9 pr-0 pl-0">
                {recreation.location}
              </div>
              {recreation.rating?.average != 0 && <div className="col-3 text-right pr-0 pl-0">
                <span className={`${loading ? "transparent-color" : ""} rating-inline`}>
                  <i className="fas fa-star mr-1"/>
                  {recreation.rating?.average}
                </span>
              </div>}
            </div>
          </a>
            <div className='propertyTitle'>
              <a href={fullUrl} target="_blank">
  <div className='propertyTitleDetails'>
              <div className={`${loading ? "transparent-color" : ""} row recreation-title mr-0 ml-0`}>
                {recreation.title}
                {search_data && search_data.price != undefined &&  <i className="fas fa-check-circle ml-1"/>}
              </div>
              {!search_data.price  && recreation.average_price_per_person && <div className="moreItm__price">
                <span>{I18n.t("recreations.form.from")}</span>
                <ul className={`${loading ? "transparent-color" : "breadcrumb--dark"} breadcrumb empty_background ml-1`}>
                  <li className={`${loading ? "transparent-color" : ""}`}>{`${recreation.average_price_per_person}${recreation.currency}`}</li>
                  <li className={`${loading ? "transparent-color" : ""}`}>{I18n.t("recreations.form.night")}</li>
                </ul>
                </div>}
                {search_data &&  search_data.price >= 0 && <div className="moreItm__price">


                  {search_data.price > 0 && (<span>{I18n.t("recreations.form.from")}</span>)}
                  <ul className={`${loading ? "transparent-color" : "breadcrumb--dark"} breadcrumb empty_background ml-1`}>
                    {search_data.price > 0 && (
                    <li className={`${loading ? "transparent-color" : ""}`}>{`${search_data.price * search_data.nights}${recreation.currency}`}</li>
                    )}
                    {!search_data.price  && (
                    <li className={`${loading ? "transparent-color" : ""}`}>-</li> )}
                    <li className={`${loading ? "transparent-color" : ""}`}>{I18n.t("recreations.form.night_for_guests", {nights: search_data.nights, guests: search_data.guests})}</li>
                  </ul>
                  </div>}
                </div>
              </a>
              <div className='propertyTitleHeart'>
                {!loading && <>
                {search_data && search_data.marked && <>
                <img
                title={I18n.t('recreations.heart.remove')}
                onClick={() => markRecreation(recreation.id)}
                src={redHeart}/>
            </>
            }
            {search_data && !search_data.marked && <>
            <img
            title={I18n.t('recreations.heart.add')}
            onClick={() => markRecreation(recreation.id)}
            src={emptyHeart}/>
        </>
        }
        </>}
        </div>
              </div>

          </div>
        }
      </div>
    )
  }
}
