import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';


export default class PlaceTypeFilter extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    const { propertyTypes, onPropertyTypeChange } = this.props;

    const hasSelected = propertyTypes.find(el => el["selected"])

    return (
      <div className="property-types-filter border-b">
        <div className="row ml-0 mt-3">
          <h2>{I18n.t("recreations.filter.place_types")}</h2>
        </div>

        <div className={'filter-options'}>
          <FormControl component="fieldset" className={'options-list'}>
            <FormGroup>
              {propertyTypes.map((item, index) => (
              <FormControlLabel
                control={<Checkbox checked={item["selected"]} onChange={onPropertyTypeChange} name={"propertyType_" + item["id"]} color={'default'} />}
                label={item["title"]}
                key={index}
              />
              ))}
            </FormGroup>
          </FormControl>
        </div>
      </div>
    )
  }
}
