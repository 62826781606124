import React from "react"

export default class NoRecreationsMessage extends React.Component {
  constructor(props) {
    super(props);
  }

  render () {
    const { smallBanners } = this.props;

    return (
      <div className="text-center no-recreations-message">
        {I18n.t("recreations.empty_recreations_message")}
      </div>
    )
  }
}
