import React from "react"
import { FormControlLabel, Switch, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 23,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    color: '#3b99b5',
    opacity: 0.48,
    '& + $track': {
      backgroundColor: '#ECF3F5',
      opacity: 1,
      border: 'none',
    },
    '&$checked': {
      transform: 'translateX(16px)',
      color: '#3b99b5',
      opacity: 1,
      '& + $track': {
        backgroundColor: '#ECF3F5',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 21,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default class AccessibilityFilter extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const {
      onlyAvailableRooms, onOnlyAvailableRoomsChange, disableOnlyAvailableFilter
    } = this.props;

    return (
      <div className="on-right">
        <Tooltip title={disableOnlyAvailableFilter ? I18n.t("recreations.filter.hint_only_available") : ""} placement="top">
          <FormControlLabel
            control={
              <IOSSwitch
                checked={onlyAvailableRooms}
                onChange={onOnlyAvailableRoomsChange}
                disabled={disableOnlyAvailableFilter}
              />
            }
            label={I18n.t('recreations.filter.only_available')}
          />
        </Tooltip>
      </div>
    )
  }
}
