import React from "react"
import ReactPaginate from 'react-paginate';
import RecreationMobile from "./RecreationMobile"

export default class RecreationsMobile extends React.Component {

  constructor(props) {
    super(props);
  }

  handlePageClick(data){
    const { changePage } = this.props;
    changePage(data.selected + 1);
  };

  render () {
    const { recreations, region, countPage, page, loading, markRecreation } = this.props;

    return (
      <>
        <div id="properties" style={{margin: '-20px'}} className={loading ? "not-clickable" : ""}>
          {recreations && recreations.map((recreation, index) => (
            <div className="col-12 mt-4" key={index}>
              <RecreationMobile
                recreation={recreation}
                region={region}
              loading={loading}
              markRecreation={markRecreation}
              />
            </div>
          ))}
          {false && smallBanners.map((banner, index) => (
            <div className="col-12 col-md-6 col-lg-4" key={index}>
              <Banner/>
            </div>
          ))}


        </div>
        <div className="mobile-pagination-block">
          <ReactPaginate
            previousLabel={'<<'}
            nextLabel={'>>'}
            breakLabel={' ... '}
            breakClassName={'break-me'}
            pageCount={countPage}
            marginPagesDisplayed={1}
            pageRangeDisplayed={1}
            onPageChange={(data) => this.handlePageClick(data)}
            forcePage={Number(page)-1}
            initialPage={Number(page)-1}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            pageClassName="page-item btn--text"
            pageLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
          />
        </div>
      </>
    )
  }
}
