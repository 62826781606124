import React from "react"

export default class RequestForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      address: "",
      name: "",
      email: "",
      information: "",
      name_property: "",
      errors: {},
      creating: true
    };
  }

  changeValue(key, value){
    const { errors } = this.state;
    errors[key] = null;
    this.setState({
      [key]: value,
      creating: true,
      errors: errors
    });
  }

  valid(){
    var valid = true;
    const { phone, name, email, address, information, name_property, errors } = this.state;

    if(phone == ""){
      valid = false;
      errors.phone = I18n.t("front.placement_request.show.errors.blank");
    }

    if(name == ""){
      valid = false;
      errors.name = I18n.t("front.placement_request.show.errors.blank");
    }

    if(address == ""){
      valid = false;
      errors.address = I18n.t("front.placement_request.show.errors.blank");
    }

    const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if(email == ""){
      valid = false;
      errors.email = I18n.t("front.placement_request.show.errors.blank");
    } else if(!reg.test(String(email).toLowerCase())){
      valid = false;
      errors.email = I18n.t("front.placement_request.show.errors.wrong_email");
    }

    this.setState({ errors: errors });

    return valid;
  }

  savePlacementRequest(){
    if(this.valid()){
      const { phone, name, email, address, information, name_property } = this.state;
      const { url } = this.props;
      const that = this;
      var params = {'placement_request': {}};

      params.placement_request.phone = phone;
      params.placement_request.name = name;
      params.placement_request.email = email;
      params.placement_request.address = address;
      params.placement_request.information = information;
      params.placement_request.name_property = name_property;

      $.ajax({
        url: url,
        dataType: 'json',
        method: 'POST',
        data: JSON.stringify(params),
        contentType: 'application/json',
        complete: (data) => (function() {
          const { errors, status } = data.responseJSON;

          if(status == "ok"){
            that.setState({
              creating: false,
              phone: "",
              name: "",
              email: "",
              address: "",
              information: "",
              name_property: ""
            });
          } else {
            that.setState({errors: errors});
          }
        })()
      })
    }
  }

  render () {
    const {
      phone, name, email, address, information, name_property, errors, creating
    } = this.state;

    return (
      <section className="sendForm" id="bottom">
        <div className="container">
          <div className="sendForm__wrap">
            <div className="sendForm__inner">
              <h2 className="sendForm__title">{I18n.t("front.placement_request.show.are_you_interest_in")}</h2>
              {creating && <p className="sendForm__sub">
                {I18n.t("front.placement_request.show.leave_your_contacts")}
              </p>}
              {creating == false && <p className="sendForm__sub success-color">
                {I18n.t("front.placement_request.show.placement_was_created")}
              </p>}
              <div className="leaveReview__form sendForm__form">
                <div className="row leaveReview__inputs">
                  <div className="sendForm__itm d-flex flex-column justify-content-end bookForm__itm order-first order-md-0 col-12 col-md-6">
                    <label className="form-label">
                      {I18n.t("front.placement_request.show.name")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="bookForm__name"
                      value={name}
                      onChange={(a) => this.changeValue('name', a.target.value)}
                    />
                    {errors.name && <div className="error-message-tooltip">{errors.name}</div>}
                  </div>

                  <div className="sendForm__itm d-flex flex-column justify-content-end bookForm__itm col-12 col-md-6">
                    <label className="form-label">
                      {I18n.t("front.placement_request.show.email")}
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="bookForm__mail"
                      value={email}
                      onChange={(a) => this.changeValue('email', a.target.value)}
                    />
                    {errors.email && <div className="error-message-tooltip">{errors.email}</div>}
                  </div>

                  <div className="sendForm__itm d-flex flex-column justify-content-end bookForm__itm order-first order-md-0 col-12 col-md-6">
                    <label className="form-label">
                      {I18n.t("front.placement_request.show.phone")}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="bookForm__tel"
                      value={phone}
                      onChange={(a) => this.changeValue('phone', a.target.value)}
                    />
                    {errors.phone && <div className="error-message-tooltip">{errors.phone}</div>}
                  </div>

                  <div className="sendForm__itm d-flex flex-column justify-content-end bookForm__itm col-12 col-md-6">
                    <label className="form-label">
                      {I18n.t("front.placement_request.show.address")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="bookForm__surname"
                      value={address}
                      onChange={(a) => this.changeValue('address', a.target.value)}
                    />
                    {errors.address && <div className="error-message-tooltip">{errors.address}</div>}
                  </div>

                  <div className="sendForm__itm d-flex flex-column justify-content-end bookForm__itm order-first order-md-0 col-12 col-md-6">
                    <label className="form-label">
                      {I18n.t("front.placement_request.show.name_property")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="bookForm__name_property"
                      value={name_property}
                      onChange={(a) => this.changeValue('name_property', a.target.value)}
                    />
                  </div>

                  <div className="sendForm__itm d-flex flex-column justify-content-end bookForm__itm col-12 col-md-6">
                    <label className="form-label">
                      {I18n.t("front.placement_request.show.information")}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="bookForm__additional_information"
                      value={information}
                      onChange={(a) => this.changeValue('information', a.target.value)}
                    />
                  </div>

                  <div className="w-100"></div>
                  <div className="col-12 col-md-5 bookForm__btn sendForm__btn">
                    <button
                      type="submit"
                      className="btn btn-block btn-success mb-3"
                      onClick={() => this.savePlacementRequest()}
                      id="send_placement_request"
                    >
                      {I18n.t("front.placement_request.show.button_send")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
