import React from "react"
import PropTypes from "prop-types"
import { Button, FormGroup, FormLabel, Switch } from "@material-ui/core/";

class RoomAvailabilities extends React.Component {
  static defaultProps = {
  };

  constructor(props) {
    super(props);
    this.state = {
      rooms: props.rooms,
      selectedTypeRoom: props.rooms[0],
      selectedAllRooms: false,
      allBookingRooms: props.booking_rooms,
      property_id: props.property_id,
      locale: props.locale
    };
  }

  changeRoomTypes(room){
    const { selectedAllRooms, allBookingRooms } = this.state;
    this.setState({ selectedTypeRoom: room });
    this.setState({ selectedAllRooms: false });
    allBookingRooms.map(room => room.selected = false);
  }

  changeRoom(bookRoom, bookingRoomsLength){
    const { allBookingRooms } = this.state;
    allBookingRooms.find(e => e.id == bookRoom.id).selected = !bookRoom.selected
    this.setState({ allBookingRooms: allBookingRooms });

    if(allBookingRooms.filter(e => e.selected).length == bookingRoomsLength) {
      this.setState({ selectedAllRooms: true });
    } else {
      this.setState({ selectedAllRooms: false });
    }
  }

  selectAllRooms(bookingRooms){
    const { selectedAllRooms } = this.state;
    this.setState({ selectedAllRooms: !selectedAllRooms });
    bookingRooms.map(room => room.selected = !selectedAllRooms);
  }

  addRoom(){
    const { property_id, locale } = this.state;
    window.location.href = `/booking/${locale}/properties/${property_id}/rooms`;
  };



  render () {
    const {
      rooms, selectedTypeRoom, allBookingRooms, selectedAllRooms
    } = this.state;

    console.log(allBookingRooms);

    const that = this;
    const bookingRooms = allBookingRooms.filter(e => e.room_id == selectedTypeRoom.id)

    return (
      <>

        <div className='dashboard place-for-form'>
          <div className='dashboard__type'>
            {rooms.map((room, index) => (
              <button
                key={index}
                type="button"
                onClick={() => that.changeRoomTypes(room)}
                className={`btn btn--transparent ${selectedTypeRoom == room ? 'is-active' : ''}`}
              >
                {room.title}
              </button>
            ))}
          </div>

          <div className="dashboard__rooms">
            <div className="btn-group">
              <button type="button" className={`btn ${selectedAllRooms ? 'btn--green btn--radius-sm' : 'btn--transparent'}`} onClick={() => that.selectAllRooms(bookingRooms)}>Усі номери</button>
              {bookingRooms.map((bookRoom, index) => (
                <button
                  key={index}
                  type="button"
                  onClick={() => that.changeRoom(bookRoom, bookingRooms.length)}
                  className={`btn ${bookRoom.selected ? 'btn--green btn--radius-sm' : 'btn--transparent'}`}
                >
                  {bookRoom.name}
                </button>
              ))}

              <div className="self-right hidden-ipad">
                <button type="button" className="btn btn--bordered btn--radius-sm" onClick={() => that.addRoom()}>
                  <span className="h4 text-regular">+</span>
                  Додати номер
                </button>
              </div>
            </div>
          </div>
        </div>


        <aside className="sidebar">
          <div className="sidebar__head hidden-ipad">
            <h3 className="text-dark">Усі двомісні номери</h3>
            <div className="sidebar__info">
              <p>Пт, 3 квітня - Сб, 4 квітня</p>
              <p>1 ніч</p>
            </div>
          </div>

          {bookingRooms.filter(e => e.selected).map(room => (
            <div className="sidebar__row sidebar__row--apartment" data-status="active">
              <div className="apartment">
                <div className="apartment__info">
                  <span className="text-small">2</span>
                  <i className="icon icon-guests"></i>
                </div>
                <div className="apartment__name">
                  <span className="text-dark">{room.name}</span>
                  <p className="text-small price">750 грн</p>
                </div>
                <div className="apartment__status">
                  <button className="btn btn--text btn--nosize"><img src="img/edit.svg" alt=""/></button>
                </div>
              </div>
            </div>
          ))}

          <form onSubmit={""}>
            <div className="sidebar__row sidebar__row--noborder hidden-ipad">
              <div className="form-group">
                <h6>Налаштування</h6>
              </div>
              <div className="form-group">
                <div className="form-label">Ціна за 1 ніч</div>
                <input type="text" className="form-control"/>
              </div>
              <div className="form-group form-group--between">
                <span className="form-label text-dark">Недоступні для бронювання</span>
                <Switch
                  checked={true}
                  onChange={""}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </div>
            </div>
            <div className="sidebar__footer hidden-ipad">
              <div className="sidebar__row">
                <div className="btn-group">
                  <button type="submit" className="btn btn--lg btn--green btn--radius-sm">Зберегти</button>
                  <div className="self-right">
                    <button type="button" className="btn btn--lg btn--bordered btn--radius-sm">Скасувати</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </aside>
      </>
    );
  }
}

RoomAvailabilities.propTypes = {
  visible: PropTypes.bool
};
export default RoomAvailabilities
