import React from "react"
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

const AirbnbSlider = withStyles({
  root: {
    color: "black",
    height: 3,
    padding: '13px 0',
  },
  thumb: {
    height: 27,
    width: 27,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    marginTop: -12,
    marginLeft: -13,
    boxShadow: '#ebebeb 0 2px 2px',
    '&:focus, &:hover, &$active': {
      boxShadow: '#ccc 0 2px 3px 1px',
    },
    '& .bar': {
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 9px)',
  },
  track: {
    height: 3,
  },
  rail: {
    color: '#d8d8d8',
    opacity: 1,
    height: 3,
  },
})(Slider);

export default class PriceFilter extends React.Component {

  constructor(props) {
    super(props);
  }

  render () {
    const { minPrice, maxPrice, onPriceChange, startMinPrice, startMaxPrice } = this.props;

    return (
      <div className="price-filter border-b">
        <div className="row ml-0 mt-3">
          <h2>{I18n.t("recreations.filter.price")}</h2>
        </div>
        <div className='row ml-0 mr-0 mb-3'>
          <label>{I18n.t('recreations.filter.per_person_per_night')}</label>
        </div>

        <div className="filter-panel">
          <div>
            <div className='row mb-4 ml-0 mr-0'>
              <div className='col-6'>
                <div className='row'>
                  <label className='ml-2'>{I18n.t('recreations.filter.min')}</label>
                </div>
                <div className='row'>
                  <input
                    value={minPrice}
                    className="price-input"
                    readOnly
                  />
                </div>
              </div>

              <div className='col-6'>
                <div className='row'>
                  <label className='ml-2'>{I18n.t('recreations.filter.max')}</label>
                </div>
                <div className='row'>
                  <input
                    value={maxPrice}
                    className="price-input"
                    readOnly
                  />
                </div>
              </div>
            </div>

            <div className='row mb-4 ml-1 mr-1'>
              <AirbnbSlider
                value={[minPrice, maxPrice]}
                step={1}
                max={startMaxPrice}
                min={startMinPrice}
                onChange={onPriceChange}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
              />
            </div>

          </div>
        </div>
      </div>
    )
  }
}
