import React from "react"
import PropTypes from "prop-types"
import FilterDates from "./FilterDates"
import FilterRegions from "./FilterRegions"
import SearchTerm from "./SearchTerm"
import GuestsFilter from "./GuestsFilter"
import ActivityFilter from "./ActivityFilter"
import ComfortFilter from "./ComfortFilter"
import FacilityFilter from "./FacilityFilter"
import LocationFilter from "./LocationFilter"
import PlaceTypeFilter from "./PlaceTypeFilter"
import RoomTypeFilter from "./RoomTypeFilter"
import PriceFilter from "./PriceFilter"
import GeneralFilter from "./GeneralFilter"
import AccessibilityFilter from "./AccessibilityFilter"
import ShowMapButton from "./ShowMapButton"
import moment from 'moment'
export default class SearchPropertiesFilter extends React.Component {
  static defaultProps = {
  };

  constructor(props) {
    super(props);
    this.state = {
      visible: false

    };
  }
  render () {
    const {
      startDate, endDate, onDatesChange,
      regions, region,
      locale, q, onQChange,
      adults, children, onGuestsChange,
      propertyTypes, onPropertyTypeChange,
      roomTypes, onRoomTypesChange,
      comfort, onComfortChange, onFiltersChange,
      facilities, onFacilitiesChange,
      locations, location, onLocationsChange,
      activities, onActivitiesChange,
      clearSection, minPrice, maxPrice, onPriceChange,
      onlyAvailableRooms, onOnlyAvailableRoomsChange,
      showMap, startMinPrice, startMaxPrice,
      disableOnlyAvailableFilter, loadRecreations,
      isShowMap, showRoomTypes, filters
    } = this.props;
    return (
      <div className='recreations-filter'>
        <div className='row'>
          <div className='col-md-12 search-filters'>
            <SearchTerm
              q={q}
              onQChange={onQChange}
              loadRecreations={loadRecreations}
            />
            <FilterDates
              startDate={startDate}
              endDate={endDate}
              onDatesChange={onDatesChange}
              loadRecreations={loadRecreations}
            />
            <GuestsFilter
              adults={adults}
              children={children}
              onGuestsChange={onGuestsChange}
              loadRecreations={loadRecreations}
            />
          </div>
        </div>
        <div className='row'>

          <div className='col-md-12 search-filters'>
            <div className='search-filters-row'>
            <PriceFilter
              minPrice={minPrice}
              maxPrice={maxPrice}
              onPriceChange={onPriceChange}
              startMinPrice={startMinPrice}
              startMaxPrice={startMaxPrice}
              clearSection={() => clearSection('price')}
              loadRecreations={loadRecreations}
          />

              {filters.map((item, index) => (
              <GeneralFilter
                options={item['options']}
                onOptionChange={onFiltersChange}
                section={item['section']}
                title={item['title']}
                clearSection={() => clearSection(item['section'])}
                loadRecreations={loadRecreations}
              />
              ))}

              {locations.length > 0 && (
            <LocationFilter
              locations={locations}
              location={location}
              onLocationsChange={onLocationsChange}
              clearSection={() => clearSection('locations')}
              loadRecreations={loadRecreations}
          />
              )}
          </div>
            <AccessibilityFilter
              onlyAvailableRooms={onlyAvailableRooms}
              onOnlyAvailableRoomsChange={onOnlyAvailableRoomsChange}
              disableOnlyAvailableFilter={disableOnlyAvailableFilter}
            />
            {<ShowMapButton showMap={showMap} isShowMap={isShowMap}/>}
          </div>
        </div>
      </div>
    )
  }
}
