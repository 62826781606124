import React from "react"
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

const AirbnbSlider = withStyles({
  root: {
    color: "black",
    height: 3,
    padding: '13px 0',
  },
  thumb: {
    height: 27,
    width: 27,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    marginTop: -12,
    marginLeft: -13,
    boxShadow: '#ebebeb 0 2px 2px',
    '&:focus, &:hover, &$active': {
      boxShadow: '#ccc 0 2px 3px 1px',
    },
    '& .bar': {
      // display: inline-block !important;
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 9px)',
  },
  track: {
    height: 3,
  },
  rail: {
    color: '#d8d8d8',
    opacity: 1,
    height: 3,
  },
})(Slider);

export default class PriceFilter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showFilter: false,
    };
    this.toggleFilter = this.toggleFilter.bind(this);
    this.clear = this.clear.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ showFilter: false });
    }
  }

  toggleFilter(){
    const { showFilter } = this.state;
    this.setState({ showFilter: !showFilter });
  }

  clear(){
    const { clearSection } = this.props;
    clearSection();
    event.preventDefault();
  }

  applyFilter(){
    const { loadRecreations } = this.props;
    this.toggleFilter();
    loadRecreations({ page: 1, setUrl: true });
  }

  render () {
    const { showFilter } = this.state;
    const {
      activities, onActivityChange, minPrice, maxPrice, onPriceChange,
      startMaxPrice, startMinPrice
    } = this.props;
    const hasSelected = minPrice != startMinPrice || maxPrice != startMaxPrice

    return (
      <div ref={this.setWrapperRef} className={`filter-block  ${showFilter ? 'selected': ''} ${hasSelected ? 'has-selected' : ''}`} >

        <span onClick={this.toggleFilter} className={'filter-title-span'} >
          <React.Fragment>{I18n.t("recreations.filter.price")}</React.Fragment>
        </span>


        {showFilter &&

        <div className="filter-panel">
          <div className="m-4">
            <div className='row'>
              <label>{I18n.t('recreations.filter.per_person_per_night')}</label>
            </div>

            <div className='row mb-4'>
              <div className='col-6'>
                <div className='row'>
                  <label className='ml-2'>{I18n.t('recreations.filter.min')}</label>
                </div>
                <div className='row'>
                  <input
                    value={minPrice}
                    className="price-input"
                    readOnly
                  />
                </div>
              </div>

              <div className='col-6'>
                <div className='row'>
                  <label className='ml-2'>{I18n.t('recreations.filter.max')}</label>
                </div>
                <div className='row'>
                  <input
                    value={maxPrice}
                    className="price-input"
                    readOnly
                  />
                </div>
              </div>
            </div>

            <div className='row mb-4 ml-1 mr-1'>
              <AirbnbSlider
                value={[minPrice, maxPrice]}
                step={1}
                max={startMaxPrice}
                min={startMinPrice}
                onChange={onPriceChange}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
              />
            </div>
          </div>

          <div className='row panel-footer'>
            <div className='col-6'>
              <a className="clear-link" href="#" onClick={this.clear}>{I18n.t('recreations.filter.clear')}</a>
            </div>

            <div className='col-6'>
              <a href="#" onClick={this.applyFilter} className='btn btn-primary apply-filter-btn'>{I18n.t('recreations.filter.apply')}</a>
            </div>
          </div>
        </div>
        }
      </div>
      )
  }
}
