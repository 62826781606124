import React from "react"
import PropTypes from "prop-types"
import moment from 'moment'
import { DayPickerRangeController, DateRangePickerPhrases } from 'react-dates';
export default class FilterDates extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      focusedInput: "startDate",
      showCalendar: false,
      stateDateWrapper: date => date,

    };
    this.onFocusChange = this.onFocusChange.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.clearDates = this.clearDates.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ showCalendar: false });
    }
  }

  onFocusChange(focusedInput) {
    this.setState({ focusedInput: !focusedInput ? "startDate" : focusedInput });
  }

  toggleFilter(){
    const { showCalendar } = this.state;
    this.setState({ showCalendar: !showCalendar });
  }

  clearDates(){
    const { onDatesChange } = this.props;
    onDatesChange({startDate: null, endDate: null});
  }

  applyFilter(){
    const { loadRecreations } = this.props;
    this.toggleFilter();
    loadRecreations({ page: 1, setUrl: true });
  }

  isDayBlocked(day){
    return moment(day).isBefore(moment(), 'day');
  }

  render () {
    const { focusedInput, showCalendar } = this.state;
    const { startDate, endDate, onDatesChange } = this.props;


    const duration = startDate && endDate ? Math.floor(moment.duration(endDate - startDate).asDays()) : 0

    const showDates = startDate && endDate;

    const oneMonth = showDates && startDate.month() == endDate.month();

    return (
      <div ref={this.setWrapperRef} className={`filter-block  ${showCalendar ? 'selected': ''} ${showDates ? 'has-selected' : ''}`} >

        <span onClick={this.toggleFilter} className={'filter-title-span'} >
          {showDates && oneMonth && (
            <React.Fragment>{startDate.format("D")}&nbsp;―&nbsp;{endDate.format("D MMM")}</React.Fragment>
          )}
          {showDates && !oneMonth && (
            <React.Fragment>{startDate.format("D MMM")}&nbsp;―&nbsp;{endDate.format("D MMM")}</React.Fragment>
            )}

          {!showDates && (
            <React.Fragment>{I18n.t("recreations.filter.select_dates")}</React.Fragment>
            )}

        </span>


        {showCalendar &&

        <div className="filter-panel date-range-panel">

          <div className='row panel-title'>
            <div className='col-10'>
              {startDate && endDate && (
              <span className='nights-selected'>
                {duration > 0 && (
                <React.Fragment>{I18n.t("recreations.filter.selected_nights", {count: duration})}</React.Fragment>
                )}
              </span>
              )}
              {startDate && startDate.format("D MMM, dddd")}
              {endDate && (<React.Fragment>&nbsp;―&nbsp;</React.Fragment>) }
              {endDate && endDate.format("D MMM, dddd")}
            </div>
            <div className='col-2'>
              <a href="#" className='close-filter-block' onClick={this.toggleFilter}></a>
            </div>
          </div>

        <div className='row'>
          <div className='col-12'>
          <DayPickerRangeController
            startDate={startDate}
            endDate={endDate}
            numberOfMonths={2}
            transitionDuration={0}
            onDatesChange={onDatesChange}
            focusedInput={focusedInput}
            onFocusChange={this.onFocusChange}
            noBorder={true}
            hideKeyboardShortcutsPanel={true}
            isDayBlocked={(day) => this.isDayBlocked(day)}
          />
        </div>
      </div>
      <div className='row panel-footer'>
        <div className='col-6'>
          <a href="#" className='clear-link' onClick={this.clearDates}>{I18n.t('recreations.filter.clear_dates')}</a>
        </div>

        <div className='col-6'>
          <a href="#" onClick={this.applyFilter} className='btn btn-primary apply-filter-btn'>{I18n.t('recreations.filter.apply')}</a>
        </div>

      </div>
        </div>
        }
      </div>
    )
  }
}
