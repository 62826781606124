import React from "react"
import mapIconBlue from 'images/2/img/_src/icons/map_icon_blue.svg'
import mapCloseIcon from 'images/2/img/_src/icons/close-icon.svg'

export default class ShowMapButton extends React.Component {

  constructor(props) {
    super(props);

  }

  render () {
    const { showMap, isShowMap } = this.props;

    return (
      <a onClick={showMap} className="show-map-button">
        {isShowMap &&
        <img src={mapCloseIcon} alt="map" />
        }
        {!isShowMap &&
        <img src={mapIconBlue} alt="map" />
        }
      </a>
    )
  }
}
