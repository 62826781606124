import React from "react"
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import GuestsFilterShowPage from "./properties/GuestsFilterShowPage"
import moment from 'moment'

export default class DateGuestsFilters extends React.Component {

  constructor(props) {
    super(props);
    moment.locale(props.locale)

    this.state = {
      focusedInput: null,
      startDate: props.search_params.start_date ? moment(props.search_params.start_date) : null,
      endDate: props.search_params.end_date ? moment(props.search_params.end_date) : null,
      adults: props.search_params.guests ? Number(props.search_params.guests) : 2,
      children: props.search_params.children ? Number(props.search_params.children) : 0,
      roomsData: props.rooms_data,
      currency: props.currency
    };
    this.onFocusChange = this.onFocusChange.bind(this);
    this.onDatesChange = this.onDatesChange.bind(this);
    this.onGuestsChange = this.onGuestsChange.bind(this);
  }

  componentDidMount() {
    const { roomsData } = this.state;
  }

  onGuestsChange(a, c){
    a = a > 0 ? a : 1;
    a = a > 10 ? 10 : a;
    c = c >=0 ? c : 0;
    c = c > 10 ? 10 : c;
    this.setState({ adults: a, children: c });
  }

  onFocusChange(focusedInput) {
    this.setState({ focusedInput: focusedInput });
  }

  onDatesChange({ startDate, endDate }) {
    const oldStartDate  = this.state.startDate;

    this.setState({
      startDate: startDate,
      endDate: oldStartDate == startDate ? endDate : null,
    });
  }

  getOrientation(){
    return $( window ).width() > 768 ? "horizontal" : "vertical";
  }

  informMessage(){
    const message = "<div class=\"roomItm__title inform-message\">\
      <span style=\"color: #b18915;\">" +
        I18n.t('front.recreations.show.dont_available_rooms') +
      "</span></div>";

    return message;
  }

  render () {
    return (<></>)
  }
}
