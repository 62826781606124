import React from "react"

export default class ClearApplyButtons extends React.Component {

  constructor(props) {
    super(props);
    this.clear = this.clear.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
  }

  clear(event){
    const { clearSection } = this.props;
    clearSection();
    event.preventDefault();
  }

  applyFilter(){
    const { loadRecreations, closeWindow } = this.props;
    loadRecreations({ page: 1, setUrl: true });
    closeWindow();
  }

  render () {
    return (
      <div className="activity-filter apply-mobile-button">
        <div className='row panel-footer show-calendar'>
          <div className='col-6'>
            <a href="#" onClick={this.clear} className={'clear-link'}>{I18n.t('recreations.filter.clear')}</a>
          </div>

          <div className='col-6 pl-0 pr-0'>
            <a href="#" onClick={this.applyFilter} className='btn btn-primary apply-filter-btn'>{I18n.t('recreations.filter.apply')}</a>
          </div>

        </div>
      </div>
    )
  }
}
