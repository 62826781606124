import React from "react"
import PropTypes from "prop-types"
import 'react-dates/initialize'
import SearchPropertiesFilter from "./properties/SearchPropertiesFilter"
import Recreations from "./properties/Recreations"
import MapDesktop from "./properties/MapDesktop"
import RecreationsMobile from "./properties/mobileVersion/RecreationsMobile"
import SearchPropertiesFilterMobile from "./properties/SearchPropertiesFilterMobile"
import SmallBanners from "./properties/SmallBanners"
import RoomCarusel from "./RoomCarusel"
import NoRecreationsMessage from "./properties/NoRecreationsMessage"
import moment from 'moment'
import ReactDOMServer from 'react-dom/server'
class SearchPropertiesPage extends React.Component {
  static defaultProps = {
  };

  constructor(props) {
    super(props);
    moment.locale(props.locale)
    const blank_property = {search_data: null, blank: true};

    this.state = {
      startDate: props.startDate ? moment(props.startDate) : null,
      endDate: props.endDate ? moment(props.endDate) : null,
      q: props.name || "",
      adults: props.guests ? Number(props.guests) : 2,
      children: props.children ? Number(props.children) : 0,
      property_types: props.property_types,
      room_types: props.room_types,
      show_room_types: props.show_room_types,
      comfort: props.comfort,
      locations: props.locations,
      location: props.location,
      facilities: props.facilities,
      activities: props.activities,
      filters: props.filters,
      minPrice: props.price_from,
      maxPrice: props.price_to,
      startMinPrice: props.start_min_price,
      startMaxPrice: props.start_max_price,
      visible: false,
      loading: false,
      locale: props.locale,
      showMap: props.show_map,
      showFilters: false,
      onlyAvailableRooms: props.only_available || false,
      recreations: [blank_property, blank_property, blank_property, blank_property],
      page: Number(props.page) || 1,
      smallBannersTop: props.small_banners_top,
      smallBannersBottom: props.small_banners_bottom,
      countPage: props.page_count || 0,
      lat: props.lat,
      lng: props.lng,
      zoom: props.zoom,
      map: null,
      markers: [],
      locale: props.locale,
      selected_property: null,
      sel_icon: null,
      def_icon: null,
    };



    this.onDatesChange = this.onDatesChange.bind(this);
    this.onPropertyTypeChange = this.onPropertyTypeChange.bind(this);
    this.onComfortChange = this.onComfortChange.bind(this);
    this.onFiltersChange = this.onFiltersChange.bind(this);
    this.onLocationsChange = this.onLocationsChange.bind(this);
    this.onFacilitiesChange = this.onFacilitiesChange.bind(this);
    this.onActivitiesChange = this.onActivitiesChange.bind(this);
    this.onRoomTypesChange = this.onRoomTypesChange.bind(this);
    this.onQChange = this.onQChange.bind(this);
    this.onGuestsChange = this.onGuestsChange.bind(this);
    this.onPriceChange = this.onPriceChange.bind(this);
    this.onOnlyAvailableRoomsChange = this.onOnlyAvailableRoomsChange.bind(this);
    this.clearSection = this.clearSection.bind(this);
    this.showMap = this.showMap.bind(this);
    this.hoverOn = this.hoverOn.bind(this);
    this.hoverOff = this.hoverOff.bind(this);
    this.setMap = this.setMap.bind(this);
    this.isMobile = this.isMobile.bind(this);
    this.scrolToSlide = this.scrollToSlide.bind(this);
    this.changeShowFilters = this.changeShowFilters.bind(this);
    this.loadRecreations = this.loadRecreations.bind(this);
    this.markRecreation = this.markRecreation.bind(this);
  }

  componentDidMount() {
    // This is component for first loading recreations;
    const { showMap } = this.state;
    if(!showMap){
      this.loadRecreations();
    }
  }

  isMobile(){
    return window.innerWidth < 768;
  }

  changePage(value){
    window.scrollTo(0, 0);
    const oldPage  = this.state.page;
    this.setState({ page: value });
    if (oldPage != value){
      this.loadRecreations({ page: value });
    }
  }

  markRecreation(id){
    const { recreations } = this.state;

    const recId = recreations.findIndex(function(el){ return el.id == id})

    const that = this;

    const search_data = JSON.parse(recreations[recId].search_data);

    $.ajax({
      url: "/favorites/" + id + (search_data['marked'] ? "/remove" : "/add"),
      dataType: 'json',
      method: (search_data['marked'] ? "DELETE" : "POST"),
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      dataType: 'json',
      contentType: 'application/json',
      complete: function(data) {
        const result = data.responseJSON;
        search_data['marked'] = !search_data['marked']
        recreations[recId].search_data = JSON.stringify(search_data)
        that.setState({recreations: recreations});
        $(".favorites-menu-link > .number").html(result.count)
    }})
  }

  changeShowFilters(value){
    this.setState({showFilters: value});
  }

  showMap(){
    const { showMap, map } = this.state;
    this.setState({showMap: !showMap}, function(){
      if(showMap){
        this.loadRecreations({page: 1, setUrl:true})
      }else{
        // this.loadRecreations({page: 1, setUrl:true });
      }
    });
    if(!showMap){
      this.setState({map: null, markers: []})
    }

    if(!showMap && this.isMobile()){
      document.body.style.position = 'fixed';
      document.body.style.top = -1 * window.scrollY + 'px';
      $(".main").css("width", window.innerWidth + "px");
    }
    if(showMap && this.isMobile()){
      const scrollY = document.body.style.top;
      document.body.style.position = '';
      document.body.style.top = '';
      window.scrollTo(0, parseInt(scrollY || '0') * -1);
    }

    return false;
  }

  setMap(map, lat, lng, zoom, callback=null){
    this.setState({map: map, lat: lat, lng: lng, zoom: zoom}, function(){if(callback){callback()}});
  }

  getPropertyPopup(r, locale){
    const url = "/" + locale + "/recreations/" + r.slug;
    const recreation = r;
    const search_data = JSON.parse(recreation.search_data);

    return ReactDOMServer.renderToStaticMarkup(
      <div>
        <a href={url} target="_blank">
          <div className="imgHolder">
            <img
            src={search_data.photo}
            alt={`${recreation.property_type_title} ${recreation.title} ${recreation.location}`}
            title={`${recreation.property_type_title} ${recreation.title} ${recreation.location}`}
            className="img-fluid"
          />

          </div>
        </a>
        <a href={url} target="_blank">
          <div className={`${recreation.hot ? "discount-hot" : ""}`}>
            <div className="row region-location-rating mr-0 ml-0">
              <div className="col-9 pr-0 pl-0">
                {recreation.location}
              </div>
              {recreation.rating?.average != 0 && <div className="col-3 text-right pr-0 pl-0">
                <span className={`rating-inline`}>
                  <i className="fas fa-star mr-1"/>
                  {recreation.rating?.average}
                </span>
              </div>}
            </div>
            <div className={` row recreation-title mr-0 ml-0`}>
              {recreation.title}
              {search_data && search_data.price != null && <i className="fas fa-check-circle ml-1"/>}
            </div>
            {!search_data.price && recreation.average_price_per_person > 0 &&  <div className="moreItm__price">
              <span>{I18n.t("recreations.form.from")}</span>
              <ul className={`"breadcrumb--dark" breadcrumb empty_background ml-1`}>
                <li className={``}>{`${recreation.average_price_per_person}${recreation.currency}`}</li>
                <li className={``}>{I18n.t("recreations.form.night")}</li>
              </ul>
            </div>}
            {search_data.price && search_data.price > 0 && <div className="moreItm__price">
              <span>{I18n.t("recreations.form.from")}</span>
              <ul className={`"breadcrumb--dark"} breadcrumb empty_background ml-1`}>
                <li className={``}>{`${search_data.price * search_data.nights}${recreation.currency}`}</li>
                <li className={``}>{I18n.t("recreations.form.night_for_guests", {nights: search_data.nights, guests: search_data.guests})}</li>
              </ul>
            </div>}
          </div>
        </a>
      </div>
     )



    //return d;
  }

  scrollToSlide(i, index){

    const {current_marker_index} = this.state;

    if (current_marker_index){
      this.hoverOff(current_marker_index);
    }
    this.hoverOn(index);
  }

  hoverOn(i){
    const { markers, def_icon, sel_icon, current_marker_index } = this.state;

    const marker = markers.find(function(m){return m && m.options.alt == i})

    if(!marker){
      return;
    }

    sel_icon.options.alt = i;
    marker.setIcon(sel_icon);
    marker.setZIndexOffset(300);

    if (current_marker_index != null){

      const sel_marker = markers.find(function(m){return m && m.options.alt == current_marker_index})
      def_icon.options.alt = i;
      sel_marker.setIcon(def_icon);
      sel_marker.setZIndexOffset(100);
    }

    this.setState({current_marker_index: i, sel_icon: sel_icon, def_icon: def_icon})
  }

  hoverOff(i){
    const { markers, def_icon, current_marker_index } = this.state;
    const marker = markers.find(function(m){return m && m.options.alt == i})
    if(!marker){
      return
    }
    def_icon.options.alt = i;
    marker.setIcon(def_icon);
    marker.setZIndexOffset(100);
    this.setState({current_marker_index: null, def_icon: def_icon})
  }

  showMapMarkers(){
    const { map, markers, locale, selected_property, recreations } = this.state;

    const selected_prop =  selected_property;

    markers.map( (e, index) => {
      const rec = recreations.find(function(r){
        const ll = e.getLatLng();
        return ll.lat == r.lat && ll.lng == r.lng;
      })
      if(!rec){
        e.remove();
        markers[index] = null;
      }
    })

    var markers2 = [];

    var def_icon = L.icon({
      iconUrl: '/img/pin.png',

      iconSize:     [24, 24], // size of the icon
      iconAnchor:   [12, 24], // point of the icon which will correspond to marker's location
      popupAnchor:  [-2, -28] // point from which the popup should open relative to the iconAnchor
    });


    var sel_icon = L.icon({
      iconUrl: '/img/pin_selected.png',

      iconSize:     [24, 24], // size of the icon
      iconAnchor:   [12, 24], // point of the icon which will correspond to marker's location
      popupAnchor:  [-2, -28] // point from which the popup should open relative to the iconAnchor
    });

    var sel_prop;
    var sel_i;
    var sel_marker;

    if (recreations == undefined){
      return
    }


    const rec_with_markers = recreations.map((e, i) => {

      if(e.lat == null || e.lng == null){
        return e;
      }

      const existingMarker = markers.find(function(m){
        if(!m){
          return false
        }
        const ll = m.getLatLng();
        return ll.lat == e.lat && ll.lng == e.lng;
      });

      var marker = null;

      if(existingMarker){
        marker = existingMarker;
      }else{


        var marker = L.marker([e.lat, e.lng], {autoPan: false, alt: e.id,autoPanSpeed: 0, opacity: 1, icon: def_icon});


        const propertyPopup = this.getPropertyPopup(e, locale);



        marker.bindPopup(propertyPopup, {autoPan: false, minWidth: 200});
        marker.on("popupopen", () => {
          this.hoverOn(e.id);
          //this.scrollToSlide(e.id, i);
        })
        marker.on("popupclose", () => {
          this.hoverOff(e.id);
        })

        marker.addTo(map);
      }
      if(selected_property && selected_property == e.id){
        sel_marker = marker;
        sel_i = id;
        sel_prop = e;
      }
      markers2.push(marker);
      e.marker = marker;
      return e
    });




    this.setState({markers: markers2, sel_icon: sel_icon, def_icon: def_icon, recreations: rec_with_markers}, function(){

      if(selected_prop && sel_prop && false){
        if (!this.isMobile() ){
          this.scrollToSlide(sel_prop.id, sel_i);
          sel_marker.openPopup();
        }else{
          this.navigateToSlide(sel_i);
        }
      }
    })
  }

  onOnlyAvailableRoomsChange(e){
    const value = e.target.checked
    this.setState({onlyAvailableRooms: value, page: 1});
    this.loadRecreations({available: value, page: 1, setUrl:true });
  }

  onPriceChange(e, value) {
    this.setState({
      minPrice: value[0],
      maxPrice: value[1]
    });
  }

  onDatesChange({ startDate, endDate }) {
    const oldStartDate  = this.state.startDate;
    this.setState({
      startDate: startDate,
      endDate: oldStartDate == startDate ? endDate : null,
    });

    if(startDate == null || endDate == null){
      this.setState({onlyAvailableRooms: false});
    }

    if(startDate && endDate){
      this.setState({onlyAvailableRooms: true});
    }

    if((startDate == null && endDate == null) && this.needRequest("date_range")){
      this.loadRecreations({ page: 1, setUrl: true, startDate: "null", endDate: "null" });
    }
  }

  onQChange(e){
    this.setState({ q: e.target.value });
  }

  onGuestsChange(a, c){
    a = a > 0 ? a : 1;
    a = a > 10 ? 10 : a;
    c = c >=0 ? c : 0;
    c = c > 10 ? 10 : c;
    this.setState({ adults: a, children: c });
    if((a == 1 && c == 0) && !this.needRequest("guests=1&children=0")){
      this.loadRecreations({ page: 1, setUrl: true, adults: a, children: c });
    }
  }

  clearSection(section){
    if(section == 'property_types') {
      const { property_types } = this.state;
      property_types.forEach((el, index, arr) => {
        arr[index]["selected"] = false;
      })
      this.setState({property_types: property_types});
      if(this.needRequest("property_types")){
        this.loadRecreations({ page: 1, setUrl: true });
      }
    }
    if(section == 'room_types') {
      const { room_types } = this.state;
      room_types.forEach((el, index, arr) => {
        arr[index]["selected"] = false;
      })
      this.setState({room_types: room_types});
      if(this.needRequestForManyElements(room_types.map(type => type.id))){
        this.loadRecreations({ page: 1, setUrl: true });
      }
    }
    if(section == 'comfort') {
      const { comfort } = this.state;
      comfort.forEach((el, index, arr) => {
        arr[index]["selected"] = false;
      })
      this.setState({comfort: comfort});
      if(this.needRequestForManyElements(comfort.map(type => type.id))){
        this.loadRecreations({ page: 1, setUrl: true });
      }
    }
    if(section == 'activities') {
      const { activities } = this.state;
      activities.forEach((el, index, arr) => {
        arr[index]["selected"] = false;
      })
      this.setState({activities: activities});

      if(this.needRequestForManyElements(activities.map(type => type.id))){
        this.loadRecreations({ page: 1, setUrl: true });
      }
    }
    if(section == 'locations') {
      const { locations } = this.state;
      locations.forEach((el, index, arr) => {
        arr[index]["selected"] = false;
      })
      this.setState({locations: locations, location: null});
      if(this.needRequest("locations") || location.length > 0){
        this.loadRecreations({ page: 1, setUrl: true });
      }
    }


    let reload = false;
      const { filters } = this.state;
    filters.forEach((el) => {
      if(el.section == section){
        el.options.forEach((e) => {
          reload ||= e["selected"];
          e["selected"] = false
        })
      }
    })

    this.setState({filters: filters});
    if(reload){
      this.loadRecreations({ page: 1, setUrl: true });
    }



    if(section == 'facilities') {
      const { facilities } = this.state;
      facilities["base"].forEach((el, index, arr) => {
        arr[index]["selected"] = false;
      })
      facilities["room"].forEach((el, index, arr) => {
        arr[index]["selected"] = false;
      })
      this.setState({facilities: facilities})

      if(this.needRequest("room_options") || this.needRequestForManyElements(facilities.base.map(type => type.id))){
        this.loadRecreations({ page: 1, setUrl: true });
      }
    }
    if(section == 'price') {
      const { startMaxPrice, startMinPrice, minPrice, maxPrice } = this.state;

      this.setState({
        minPrice: startMinPrice,
        maxPrice: startMaxPrice
      })

      if(!this.needRequest(`price_to=${startMaxPrice}&price_from=${startMinPrice}`)){
        this.loadRecreations({ page: 1, setUrl: true, minPrice: startMinPrice, maxPrice: startMaxPrice });
      }
    }

    if(section == "clearDates") {
      this.setState({
        startDate: null,
        endDate: null,
        onlyAvailableRooms: false
      });

      if(this.needRequest("date_range")){
        this.loadRecreations({ page: 1, setUrl: true, startDate: "null", endDate: "null" });
      }
    }

    if(section == 'all') {
      const {
        property_types, room_types, comfort, activities, locations, facilities,
        startMaxPrice, startMinPrice, minPrice, maxPrice
      } = this.state;

      property_types.forEach((el, index, arr) => {
        arr[index]["selected"] = false;
      })
      room_types.forEach((el, index, arr) => {
        arr[index]["selected"] = false;
      })
      comfort.forEach((el, index, arr) => {
        arr[index]["selected"] = false;
      })
      activities.forEach((el, index, arr) => {
        arr[index]["selected"] = false;
      })
      locations.forEach((el, index, arr) => {
        arr[index]["selected"] = false;
      })
      facilities["base"].forEach((el, index, arr) => {
        arr[index]["selected"] = false;
      })
      facilities["room"].forEach((el, index, arr) => {
        arr[index]["selected"] = false;
      })

      this.onGuestsChange(1, 0);

      this.setState({
        property_types: property_types,
        room_types: room_types,
        comfort: comfort,
        activities: activities,
        locations: locations,
        facilities: facilities,
        minPrice: startMinPrice,
        maxPrice: startMaxPrice,
        startDate: null,
        endDate: null,
        onlyAvailableRooms: false,
        q: ""
      });

      if(this.needRequest("room_options") ||
          this.needRequestForManyElements(facilities.base.map(type => type.id)) ||
          this.needRequest("locations") ||
          this.needRequestForManyElements(activities.map(type => type.id)) ||
          this.needRequestForManyElements(comfort.map(type => type.id)) ||
          this.needRequestForManyElements(room_types.map(type => type.id)) ||
          this.needRequest("property_types") ||
          !this.needRequest("guests=1&children=0") ||
          this.needRequest("date_range") ||
          this.checkTerm()
        ){
          this.loadRecreations({
            page: 1,
            setUrl: true,
            minPrice: startMinPrice,
            maxPrice: startMaxPrice,
            startDate: "null",
            endDate: "null",
            term: "null"
          });
      }
    }

    return false;
  }

  checkTerm(){
    const url = window.location.href;
    const path_array = url.split('&');
    const name = path_array.find(e => e.includes("name"));

    return name ? name != "name=" : false
  }

  needRequestForManyElements(array){
    const url = window.location.href;
    const path_array = url.split('&');
    const listing_options_ids = path_array.find(e => e.includes("listing_options"));

    if(listing_options_ids){
      var result = false;

      array.forEach(id => {
        if(listing_options_ids.includes(id)){
          result = true;
        }
      })

      return result;
    } else {
      return false;
    }
  }

  needRequest(value){
    const url = window.location.href;
    return url.includes(value);
  }

  setUrl(params){
    var url = `${window.location.href}?`;
    url = url.split("?")[0];

    const array_params = [];
    Object.entries(params).forEach(([key, value]) => {
      if(!['t_lat', 't_lng', 'b_lat', 'b_lng'].includes(key) && value !== null){
        array_params.push(`${key}=${value}`);
      }
    });

    window.history.pushState({}, 'title', url + "?" + array_params.join("&"));
  }

  loadRecreations(params, callback = null){

    const { locale, loading, showMap } = this.state;

    if(loading){
      return;
    }

    console.log('invoking search with', params)

    const search_params = this.getPropertiesSearchParams(params);

    if (search_params['t_lat'] &&  search_params['t_lat'] == search_params['b_lat']){
      return;
    }
     console.log("____________________________");
     console.log(search_params);
    // console.log(params);
    // console.log("____________________________");
    if(params?.setUrl){
      this.setUrl(search_params);
    }
    // if (showMap && search_params.t_lat == search_params.b_lat){
    //   return;
    // }
    this.setState( { loading: true } );

    const that = this;

    // if (propSlider){
    //   $(".properties-slider").slick('unslick');
    // }
    $.ajax({
      url: "/" + locale + "/recreations/search",
      dataType: 'json',
      method: 'POST',
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      data: JSON.stringify(search_params),
      dataType: 'json',
      contentType: 'application/json',
      complete: function(data) {
        const result = data.responseJSON;
        that.setState({recreations: result.recreations, countPage: result.page_count}, function(){
          if (showMap){
            that.showMapMarkers();
          }
          that.setState( { loading: false } );
          if (callback){
            callback(result.recreations.length)
          }
        });
    }})
  }


  getPropertiesSearchParams(parameters){
    const {
      maxPrice, minPrice, property_types, comfort, room_types, activities,
      facilities, locations, location, onlyAvailableRooms, startDate, endDate, adults,
      children, q, page, showMap, map, filters
    } = this.state;

    const { region_slug } = this.props;
    // map
    var params = {};

    if (showMap && map){


      const bounds = map.getBounds()
      const t_point = bounds.getNorthWest();
      const b_point = bounds.getSouthEast();

      params['t_lat'] = t_point.lat;
      params['t_lng'] = t_point.lng;
      params['b_lat'] = b_point.lat;
      params['b_lng'] = b_point.lng;
      params['zoom'] = map.getZoom();
      params['center_lat'] = map.getCenter().lat;
      params['center_lng'] = map.getCenter().lng;
    }else{
      params['t_lat'] = null;
      params['t_lng'] = null;
      params['b_lat'] = null;
      params['b_lng'] = null;
      params['zoom'] = null;
      params['center_lat'] = null;
      params['center_lng'] = null;
    }

    if(parameters?.minPrice){
      params['price_to'] = parameters.maxPrice;
      params['price_from'] = parameters.minPrice;
    } else {
      params['price_to'] = maxPrice;
      params['price_from'] = minPrice;
    }

    if(parameters?.adults){
      params['guests'] = parameters.adults;
      params['children'] = parameters.children;
    } else {
      params['guests'] = adults;
      params['children'] = children;
    }
    params['name'] = parameters?.term ? "" : q;
    params['region'] = region_slug;

    params['page'] = parameters?.page || page;
    if(parameters?.page){
      this.setState({page: parameters.page});
    }

    if(parameters?.startDate && parameters?.endDate){

    } else if (startDate && endDate){
      params['date_range'] = `${startDate.format('DD-MM-YYYY')}/${endDate.format('DD-MM-YYYY')}`;
      const available = parameters?.available

      if (available || available == false){
        params['available'] = available;
      } else {
        params['available'] = onlyAvailableRooms;
      }
    }

    const selected_property_types = this.filterDates(property_types);
    if (selected_property_types.length > 0){
      params['property_types'] = selected_property_types;
    }

    if (location){
      params['location'] = location;
    }

    const selected_room_options = this.filterDates(facilities.room);
    if (selected_room_options.length > 0){
      params['room_options'] = selected_room_options;
    }

    const selected_locations = this.filterDates(locations);
    if (selected_locations.length > 0){
      params['locations'] = selected_locations;
    }

    const selected_location = this.filterLocation(locations);
    if (selected_location.length > 0){
      params['location_id'] = selected_location;
    }

    var selected_listing_options = "";
    selected_listing_options = this.concatDates(selected_listing_options, comfort);
    selected_listing_options = this.concatDates(selected_listing_options, room_types);
    selected_listing_options = this.concatDates(selected_listing_options, activities);
    selected_listing_options = this.concatDates(selected_listing_options, facilities.base);

    var all_filters = [];
    filters.forEach((el) => {
      all_filters = all_filters.concat(el.options);
    })
    selected_listing_options = this.concatDates(selected_listing_options, all_filters);

    if (selected_listing_options.length > 0){
      params['listing_options'] = selected_listing_options;
    }

    return params;
  }

  concatDates(startedOptions, dates){
    const filtered = this.filterDates(dates);

    if(startedOptions.length > 0 && filtered.length > 0){
      return startedOptions + "," + filtered;
    } else if(startedOptions.length == 0 && filtered.length > 0){
      return filtered;
    } else if(filtered.length == 0){
      return startedOptions;
    }
  }

  filterLocation(array){
    const selected = array.filter(a => a.selected && a.location);
    const result = [];
    selected.forEach(element => result.push(element.id));

    return result.join(",");
  }

  filterDates(array){
    const selected = array.filter(a => a.selected && !a.location);
    const result = [];
    selected.forEach(element => result.push(element.id));

    return result.join(",");
  }

  onPropertyTypeChange(event){
    const { property_types } = this.state;
    const clickedId = event.target.name.split("_")[1];
    property_types.forEach((el) => {
      if (el["id"] == clickedId){
        el["selected"] = event.target.checked
      }
    })
    this.setState( { property_types: property_types } )
  }

  onComfortChange(event){
    const { comfort } = this.state;
    const clickedId = event.target.name.split("_")[1];
    comfort.forEach((el) => {
      if (el["id"] == clickedId){
        el["selected"] = event.target.checked
      }
    })
    this.setState( { comfort: comfort } )
  }

  onRoomTypesChange(event){
    const { room_types } = this.state;
    const clickedId = event.target.name.split("_")[1];
    room_types.forEach((el) => {
      if (el["id"] == clickedId){
        el["selected"] = event.target.checked
      }
    })
    this.setState( { room_types: room_types } )
  }

  onFacilitiesChange(event){
    const { facilities } = this.state;
    const clickedId = event.target.name.split("_")[1];
    facilities[event.target.name.split("_")[0]].forEach((el) => {
      if (el["id"] == clickedId){
        el["selected"] = event.target.checked
      }
    })
    this.setState( { facilities: facilities } )
  }

  onActivitiesChange(event){
    const { activities } = this.state;
    const clickedId = event.target.name.split("_")[1];
    activities.forEach((el) => {
      if (el["id"] == clickedId){
        el["selected"] = event.target.checked
      }
    })
    this.setState( { activities: activities } )
  }

  onFiltersChange(event){
    const { filters } = this.state;
    const clickedId = event.target.name.split("_")[1];
    filters.forEach((el) => {
      el.options.forEach((o)=>{
        if (o["id"] == clickedId){
          o["selected"] = event.target.checked
        }
      })
    })
    this.setState( { filters: filters } )
  }

  onLocationsChange(event){
    const { locations } = this.state;
    const clickedId = event.target.name.split("_")[1];
    locations.forEach((el) => {
      if (el["id"] == clickedId){
        el["selected"] = event.target.checked
      }
    })
    this.setState( { locations: locations } )
  }

  render () {
    const {
      startDate, endDate, q, startMinPrice, startMaxPrice, recreations,
      adults, filters, children, location, minPrice, maxPrice, onlyAvailableRooms, showFilters,
      page, countPage, smallBannersTop, smallBannersBottom, loading, showMap, lat, lng, zoom,
      map
    } = this.state;

    const { region,
      regions,
      locale,
      property_types,
      room_types,
      show_room_types,
      facilities,
      activities,
      locations,
      comfort,
    } = this.props;

    const disableOnlyAvailableFilter = startDate == null || endDate == null;

    return (
      <div className="section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-12">
              {!this.isMobile() && <>
              <div className="desctop-variant">
                <SearchPropertiesFilter
                startDate={startDate}
                endDate={endDate}
                onDatesChange={this.onDatesChange}
                region={region}
                regions={regions}
                locale={locale}
                q={q}
                onQChange={this.onQChange}
                adults={adults}
                propertyTypes={property_types}
                comfort={comfort}
                roomTypes={room_types}
                filters={filters}
                showRoomTypes={show_room_types}
                facilities={facilities}
                activities={activities}
                locations={locations}
                location={location}
                onPropertyTypeChange={this.onPropertyTypeChange}
                onComfortChange={this.onComfortChange}
                onFacilitiesChange={this.onFacilitiesChange}
                onActivitiesChange={this.onActivitiesChange}
                onFiltersChange={this.onFiltersChange}
                onLocationsChange={this.onLocationsChange}
                onRoomTypesChange={this.onRoomTypesChange}
                children={children}
                onGuestsChange={this.onGuestsChange}
                clearSection={this.clearSection}
                minPrice={minPrice}
                maxPrice={maxPrice}
                onPriceChange={this.onPriceChange}
                onlyAvailableRooms={onlyAvailableRooms}
                onOnlyAvailableRoomsChange={this.onOnlyAvailableRoomsChange}
                showMap={this.showMap}
                isShowMap={showMap}
                startMinPrice={startMinPrice}
                startMaxPrice={startMaxPrice}
                disableOnlyAvailableFilter={disableOnlyAvailableFilter}
                loadRecreations={this.loadRecreations}
              />

                {!this.isMobile() && showMap && <>
                <MapDesktop
                recreations={recreations}
                region={region}
                page={page}
                locale={locale}
                map={map}
                setMap={this.setMap}
                loadRecreations={this.loadRecreations}
                markRecreation={this.markRecreation}
                countPage={countPage}
                changePage={(value) => this.changePage(value)}
                loading={loading}
                lat={lat}
                lng={lng}
                zoom={zoom}
                hoverOn={this.hoverOn}
                hoverOff={this.hoverOff}
              />
                  </>}

                  {!showMap &&  <>
                  {smallBannersTop.length > 0 &&  <SmallBanners smallBanners={smallBannersTop} /> }
                  {recreations.length > 0 && <Recreations
                  recreations={recreations}
                  region={region}
                  page={page}
                  markRecreation={this.markRecreation}
                  countPage={countPage}
                  changePage={(value) => this.changePage(value)}
                  loading={loading}
                /> || <>
                    <NoRecreationsMessage/>
                    <SmallBanners smallBanners={smallBannersBottom} />
                    </>}
                    </>}
                  </div>
                  </>}
      {this.isMobile() && <>
          <div className="mobile-variant">
            <SearchPropertiesFilterMobile
            startDate={startDate}
            endDate={endDate}
            onDatesChange={this.onDatesChange}
            region={region}
            regions={regions}
            locale={locale}
            q={q}
            onQChange={this.onQChange}
            adults={adults}
            propertyTypes={property_types}
            comfort={comfort}
            roomTypes={room_types}
            showRoomTypes={show_room_types}
            facilities={facilities}
            activities={activities}
            locations={locations}
            location={location}
            filters={filters}
            onFiltersChange={this.onFiltersChange}
            onPropertyTypeChange={this.onPropertyTypeChange}
            onComfortChange={this.onComfortChange}
            onFacilitiesChange={this.onFacilitiesChange}
            onActivitiesChange={this.onActivitiesChange}
            onLocationsChange={this.onLocationsChange}
            onRoomTypesChange={this.onRoomTypesChange}
            children={children}
            onGuestsChange={this.onGuestsChange}
            clearSection={this.clearSection}
            minPrice={minPrice}
            maxPrice={maxPrice}
            onPriceChange={this.onPriceChange}
            onlyAvailableRooms={onlyAvailableRooms}
            onOnlyAvailableRoomsChange={this.onOnlyAvailableRoomsChange}
            showMap={this.showMap}
            isShowMap={showMap}
            showFilters={showFilters}
            changeShowFilters={this.changeShowFilters}
            startDate={startDate}
            endDate={endDate}
            onDatesChange={this.onDatesChange}
            startMinPrice={startMinPrice}
            startMaxPrice={startMaxPrice}
            disableOnlyAvailableFilter={disableOnlyAvailableFilter}
            loadRecreations={this.loadRecreations}
            recreations={recreations}
            map={map}
            setMap={this.setMap}
            loading={loading}
            lat={lat}
            lng={lng}
            zoom={zoom}
            hoverOn={this.hoverOn}
            hoverOff={this.hoverOff}
          />
              <SmallBanners smallBanners={smallBannersTop} showMap={showMap} />
              {recreations && recreations.length > 0 && !showMap &&  <RecreationsMobile
              recreations={recreations}
              region={region}
              page={page}
              markRecreation={this.markRecreation}
              countPage={countPage}
              changePage={(value) => this.changePage(value)}
              loading={loading}
            /> || <>
                <NoRecreationsMessage/>
                <SmallBanners smallBanners={smallBannersBottom} />
                </>}
          </div>
          </>}
      </div>
          </div>
        </div>
      </div>
    )
  }

}
SearchPropertiesPage.propTypes = {
  visible: PropTypes.bool
};
export default SearchPropertiesPage
