import React from "react"
import PropTypes from "prop-types"
import Recreation from "./properties/Recreation"
import RecreationMobile from "./properties/mobileVersion/RecreationMobile"
class FavoriteList extends React.Component {
  static defaultProps = {
  };

  constructor(props) {
    super(props);
    this.state = {
      recreations: props.recreations,
      locale: props.locale
    }

    this.markRecreation = this.markRecreation.bind(this);
    this.isMobile = this.isMobile.bind(this);
  }

  isMobile(){
    return window.innerWidth < 768;
  }

  markRecreation(id){
    const { recreations } = this.state;

    const recId = recreations.findIndex(function(el){ return el.id == id})

    const that = this;

    const search_data = JSON.parse(recreations[recId].search_data);

    $.ajax({
      url: "/favorites/" + id + (search_data['marked'] ? "/remove" : "/add"),
      dataType: 'json',
      method: (search_data['marked'] ? "DELETE" : "POST"),
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      dataType: 'json',
      contentType: 'application/json',
      complete: function(data) {
        const result = data.responseJSON;
        search_data['marked'] = !search_data['marked']
        recreations[recId].search_data = JSON.stringify(search_data)
        that.setState({recreations: recreations});
        $(".favorites-menu-link > .number").html(result.count)
    }})
  }

  render () {
    const { recreations, region, markRecreation } = this.props;

    return (
      <div id="properties">
        <div className="row">

          {recreations && recreations.map((recreation, index) => (
          <>
            {!this.isMobile() && <div className="col-4 col-md-4 col-lg-3 mt-2" key={index}>
            <Recreation
            recreation={recreation}
            region={region}
            loading={false}
            markRecreation={this.markRecreation}
          />
            </div>
            }
            {this.isMobile() && <div className="col-12 mt-4" key={index}>
              <RecreationMobile
              recreation={recreation}
              region={region}
              markRecreation={this.markRecreation}
            />
              </div>
              }
          </>
              ))}
        </div>
      </div>
    )
  }
}

export default FavoriteList
