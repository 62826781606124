import React from "react"
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
export default class FacilityFilter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showFilter: false,
    };
    this.toggleFilter = this.toggleFilter.bind(this);
    this.clear = this.clear.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    const that = this;
    $('#facilitiesModal').on('hidden.bs.modal', function (e) {
      that.setState({ showFilter: false });
    })
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ showFilter: false });
    }
  }

  toggleFilter(){
    const { showFilter } = this.state;
    this.setState({ showFilter: !showFilter });
    if (!showFilter){
      $('#facilitiesModal').modal('show')
    }else{
      $('#facilitiesModal').modal('hide')
    }
  }

  clear(event){
    const { clearSection } = this.props;
    clearSection();
    event.preventDefault();
  }

  applyFilter(){
    const { loadRecreations } = this.props;
    this.toggleFilter();
    loadRecreations({ page: 1, setUrl: true });
  }

  render () {
    const { showFilter } = this.state;
    const { facilities, onFacilitiesChange } = this.props;

    const hasSelected = facilities["base"].concat(facilities["room"]).find(el => el["selected"]);

    return (
      <div ref={this.setWrapperRef} className={`filter-block  ${showFilter ? 'selected': ''} ${hasSelected ? 'has-selected' : ''}`} >

        <span onClick={this.toggleFilter} className={'filter-title-span'} >
          <React.Fragment>{I18n.t("recreations.filter.facilities")}</React.Fragment>
        </span>


        <div className="modal"  tabIndex="-1" id="facilitiesModal">
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="options-title">{I18n.t("recreations.filter.facilities_base")}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div className="row">

                  {facilities['base'].map((item, index) => (
                  <div className='col-md-4' key={index}>
                    <FormControlLabel
                    control={<Checkbox checked={item["selected"]} onChange={onFacilitiesChange} name={"base_" + item["id"]} color={'default'} />}
                    label={item["title"]}
                  />
                    </div>
                    ))}
                  </div>
                <h5 className="options-title mt-4">{I18n.t("recreations.filter.facilities_room")}</h5>
                <div className="row">

                  {facilities['room'].map((item, index) => (
                  <div className='col-md-4' key={index}>
                    <FormControlLabel
                    control={<Checkbox checked={item["selected"]} onChange={onFacilitiesChange} name={"room_" + item["id"]} color={'default'} />}
                    label={item["title"]}
                  />
                    </div>
                    ))}
                  </div>
              </div>
              <div className="modal-footer">
                    <a href="#" onClick={this.clear} className={'clear-link'}>{I18n.t('recreations.filter.clear')}</a>
                    <a href="#" onClick={this.applyFilter} className='btn btn-primary apply-filter-btn'>{I18n.t('recreations.filter.apply')}</a>
              </div>
            </div>
          </div>
  </div>

      </div>
      )
  }
}
