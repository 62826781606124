import React from "react"
import Recreation from "../Recreation"

export default class MapMobile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      lat: props.lat,
      lng: props.lng,
      zoom: props.zoom,
      markers: [],
      locale: props.locale,
      selected_property: null,
      sel_icon: null,
      def_icon: null,
    }
  }

  componentDidMount(){
    const that = this;
    this.initMap('mobile-map-box');

  }

  initMap(id){
    const { lat, lng, zoom } = this.state;
    const { map, loadRecreations, setMap } = this.props;
    if(!map){
      $("#mobile-map-box").css('width', '100%');
      const h1 = parseInt($(".header__wrap").css("height"));
      const h2 = parseInt($(".header__wrap").css("margin-top"));
      const h = "calc(100vh - " + (h1 + (h2 * 2) - 10) + "px)";
      $(".mobileMapBox").css('height', h)
      $("#mobile-map-box").css('min-height', h);
      const map2 = L.map(id,
        {zoomAnimation: true,
          tapTolerance: 30,
          doubleClickZoom: true,
          scrollWheelZoom: false,
          fadeAnimation: true});

      map2.on('load', (e) => {
        console.log('map loaded');
        e.target._onResize();;
      });


      map2.setView([lat, lng], zoom);

      const that = this;

      L.tileLayer( 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
        subdomains: ['a','b','c'],
      }).addTo( map2 );
      map2.on('moveend', (e) => {
        console.log('moved');
        const center = map2.getCenter();
        setMap(map2, center.lat, center.lng, map2.getZoom());
        loadRecreations({ page: 1, setUrl: true }, function(count){
        });
      });
      setMap(map2, lat, lng, zoom, function(){
        loadRecreations({ page: 1, setUrl: true }, function(count){
        });
      })
    }else{
      map._onResize();
      loadRecreations({ page: 1, setUrl: true })
    }
  }


  render () {
    const { recreations, region, loading, hoverOn, hoverOff } = this.props;

    return (
      <div id="mobile-map-place" className={loading ? "not-clickable-f" : ""}>
        <div id='mobile-map-box'></div>
      </div>
      )
}
}


