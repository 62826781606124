import React from "react"
import ReactPaginate from 'react-paginate';
import Recreation from "./Recreation"

export default class Recreations extends React.Component {

  constructor(props) {
    super(props);
  }

  handlePageClick(data){
    const { changePage } = this.props;
    changePage(data.selected + 1);
  };

  render () {
    const { recreations, region, countPage, page, loading, markRecreation } = this.props;

    return (
      <div id="properties" className={loading ? "not-clickable ml-3 mr-3" : "ml-3 mr-3"}>
        <div className="row">
          {recreations && recreations.map((recreation, index) => (
            <div className="col-4 col-md-3 col-lg-2 mt-2 property-box-col" key={index}>
              <Recreation
                recreation={recreation}
                region={region}
                loading={loading}
                markRecreation={markRecreation}
              />
            </div>
          ))}
        </div>
        <div className="main-pagination-block">
          <ReactPaginate
            previousLabel={I18n.t('booking.orders.previous')}
            nextLabel={I18n.t('booking.orders.next')}
            breakLabel={' ... '}
            breakClassName={'break-me'}
            pageCount={countPage}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            onPageChange={(data) => this.handlePageClick(data)}
            forcePage={Number(page)-1}
            initialPage={Number(page)-1}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            pageClassName="page-item btn--text"
            pageLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
          />
        </div>
      </div>
    )
  }
}
