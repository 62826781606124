import React from "react"
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
export default class GeneralFilter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showFilter: false,
    };
    this.toggleFilter = this.toggleFilter.bind(this);
    this.clear = this.clear.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ showFilter: false });
    }
  }

  toggleFilter(){
    const { showFilter } = this.state;
    this.setState({ showFilter: !showFilter });
    // event.preventDefault();
  }

  clear(event){
    const { clearSection } = this.props;
    clearSection();
    event.preventDefault();
  }

  applyFilter(){
    const { loadRecreations } = this.props;
    this.toggleFilter();
    loadRecreations({ page: 1, setUrl: true });
  }

  render () {
    const { showFilter } = this.state;
    const { options, onOptionChange, section, title } = this.props;

    const hasSelected = options.find(el => el["selected"])

    return (
      <div ref={this.setWrapperRef} className={`filter-block  ${showFilter ? 'selected': ''} ${hasSelected ? 'has-selected' : ''}`} >

        <span onClick={this.toggleFilter} className={'filter-title-span'} >
          <React.Fragment>{title}</React.Fragment>
        </span>


        {showFilter &&

        <div className="filter-panel">
          <div className={'filter-options'}>
            <FormControl component="fieldset" className={'options-list'}>
              <FormGroup>
                {options.map((item, index) => (
                <FormControlLabel
                  control={<Checkbox checked={item["selected"]} onChange={onOptionChange} name={`${section}_` + item["id"]} color={'default'} />}
                  label={item["title"]}
                  key={index}
                />
                ))}
              </FormGroup>
            </FormControl>
          </div>
          <div className='row panel-footer'>
            <div className='col-6'>
              <a href="#" onClick={this.clear} className={'clear-link'}>{I18n.t('recreations.filter.clear')}</a>
            </div>

            <div className='col-6'>
              <a href="#" onClick={this.applyFilter} className='btn btn-primary apply-filter-btn'>{I18n.t('recreations.filter.apply')}</a>
            </div>

          </div>
        </div>
        }
      </div>
      )
  }
}
