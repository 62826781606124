import React from "react"

export default class NewReviewService extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      reviewable: this.props.reviewable,
      service: "0",
      name: "",
      email: "",
      body: "",
      locationName: this.props.location_name,
      errors: {},
      creating: true
    };
  }

  changeCreating(value){
    this.setState({creating: value});
  }

  changeBody(value){
    this.setState({body: value});
    const { errors } = this.state;
    errors.body = null;
    this.setState({errors: errors});
  }

  changeService(value){
    this.setState({service: value});
    const { errors } = this.state;
    errors.service = null;
    this.setState({errors: errors});
  }

  changeName(value){
    this.setState({name: value});
    const { errors } = this.state;
    errors.name = null;
    this.setState({errors: errors});
  }

  changeEmail(value){
    this.setState({email: value});
    const { errors } = this.state;
    errors.email = null;
    this.setState({errors: errors});
  }

  closeWindow(){
    this.setState({
      creating: true,
      service: "0",
      name: "",
      email: "",
      body: ""
    });

    $('body').removeClass('no-scroll');
    $('.modal-review').removeClass('active');
  }

  valid(){
    var valid = true;
    const { service, name, email, body, errors } = this.state;

    if(service == "0"){
      valid = false;
      errors.service = I18n.t("reviews.error.service");
    }

    if(name == ""){
      valid = false;
      errors.name = I18n.t("reviews.error.blank");
    }

    if(body == ""){
      valid = false;
      errors.body = I18n.t("reviews.error.blank");
    }

    const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if(email == ""){
      valid = false;
      errors.email = I18n.t("reviews.error.blank");
    } else if(!reg.test(String(email).toLowerCase())){
      valid = false;
      errors.email = I18n.t("reviews.error.wrong_email");
    }

    this.setState({ errors: errors });

    return valid;
  }

  saveReview(){
    if(this.valid()){
      const { service, name, email, body, reviewable } = this.state;

      const { locale, create_review_url_date } = this.props;
      const that = this;
      var params = {'review': {}};

      params.review.service = service;
      params.review.name = name;
      params.review.email = email;
      params.review.body = body;
      params[create_review_url_date.key] = reviewable.id;

      $.ajax({
        url: create_review_url_date.url,
        dataType: 'json',
        method: 'POST',
        data: JSON.stringify(params),
        contentType: 'application/json',
        complete: (data) => (function() {
          const { errors, status } = data.responseJSON;

          if(status == "ok"){
            that.setState({creating: false});
          } else {
            that.setState({errors: errors});
          }
        })()
      })
    }
  }

  render () {
    const {
      service, name, email, reviewable, body, locationName, errors, creating
    } = this.state;

    return (
      <div className="modal-review">
        <div className="leaveReview">

          <div className="leaveReview__title border-b">
            <h2>{I18n.t("front.property.add_comment")}</h2>
            <span className="leaveReview__desc">{`${reviewable.title} - ${locationName}`}</span>
            <a className="close-btn" onClick={() => this.closeWindow()}></a>
          </div>

          {creating && <>
            <div className="row leaveReview__ranges no-gutters">

              <div className="col-12 col-sm txt">
                <div className="range-group">
                  <label className="form-label">{I18n.t("front.property.service")}</label>
                  <ul className="breadcrumb empty_background">
                    <li>{service}</li>
                    <li>5</li>
                  </ul>
                </div>
                <input
                  type="range"
                  className="form-range"
                  id="range3"
                  min="0"
                  max="5"
                  step="1"
                  value={service}
                  onChange={(a) => this.changeService(a.target.value)}
                />
                {errors.service && <label className="form-label error-message">{errors.service}</label>}
              </div>
            </div>

            <div className="row leaveReview__inputs no-gutters">
              <div className="leaveReview__itm col-12 col-md-6">
                <label className="form-label">{I18n.t("front.property.client_name")}</label>
                <input
                  type="text"
                  className="form-control"
                  id="inputEmail4"
                  value={name}
                  onChange={(a) => this.changeName(a.target.value)}
                />
                {errors.name && <label className="form-label error-message">{errors.name}</label>}
              </div>
              <div className="leaveReview__itm col-12 col-md-6">
                <label className="form-label">{I18n.t("front.property.email_address")}</label>
                <input
                  type="email"
                  className="form-control"
                  id="inputPassword4"
                  value={email}
                  onChange={(a) => this.changeEmail(a.target.value)}
                />
                {errors.email && <label className="form-label error-message">{errors.email}</label>}
              </div>

              {false && <div className="col-12 leaveReview__itm-col">
                <label className="form-label margin-bottom-null">{I18n.t("front.property.range_dates")}</label>
                <div className="row">
                  <div className="col-12 col-md-6 leaveReview__itm-col">
                    <label className="form-label">{I18n.t("reviews.arrival")}</label>
                    <input
                      type="date"
                      className="form-control"
                      id="inputDate"
                      value={startDate}
                      onChange={(a) => this.changeStartDate(a.target.value)}
                    />
                    {errors.start_date && <label className="form-label error-message">{errors.start_date}</label>}

                  </div>
                  <div className="col-12 col-md-6 leaveReview__itm-col">
                    <label className="form-label">{I18n.t("reviews.departure")}</label>
                    <input
                      type="date"
                      className="form-control"
                      id="inputDate"
                      value={endDate}
                      onChange={(a) => this.changeEndDate(a.target.value)}
                    />
                    {errors.end_date && <label className="form-label error-message">{errors.end_date}</label>}
                  </div>
                </div>
              </div>}

              <div className="col-12 leaveReview__itm-col">
                <label className="form-label">{I18n.t("front.property.your_comment")}</label>
                <textarea
                  className="form-control leaveReview__textarea"
                  id="leaveReview__textarea"
                  value={body}
                  onChange={(a) => this.changeBody(a.target.value)}
                >
                </textarea>
                {errors.body && <label className="form-label error-message">{errors.body}</label>}
              </div>

              <div className="col-auto leaveReview__btn">
                <button
                  type="submit"
                  className="btn btn-block btn-primary mb-3"
                  onClick={() => this.saveReview()}
                >
                  {I18n.t("front.property.add_comment")}
                </button>
              </div>
            </div>
          </>}

          {creating == false && <div className="row leaveReview__inputs no-gutters">
            <div className="col-12">
              <label className="label-success-message">{I18n.t("front.reviews.added")}</label>
            </div>
            <div className="col-12 leaveReview__btn">
              <button
                type="submit"
                className="btn btn-block btn-primary mb-3"
                onClick={() => this.closeWindow()}
              >
                {I18n.t("actions.close")}
              </button>
            </div>
          </div>}

        </div>
      </div>
    )
  }
}
