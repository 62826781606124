import React from "react"

import emptyHeart from 'images/empty_heart.svg'
import redHeart from 'images/red_heart.svg'

export default class StarProperty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: props.selected,
    };
    this.markRecreation = this.markRecreation.bind(this);
  }
  markRecreation(){
    const { selected } = this.state;

    const that = this;

    $.ajax({
      url: "/favorites/" + this.props.id + (selected ? "/remove" : "/add"),
      dataType: 'json',
      method: (selected ? "DELETE" : "POST"),
      beforeSend: function(xhr) {xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'))},
      dataType: 'json',
      contentType: 'application/json',
      complete: function(data) {
        const result = data.responseJSON;
        that.setState({selected: result.selected});
        $(".favorites-menu-link > .number").html(result.count)
      }})
  }

  render () {
    const {
      selected
    } = this.state;
    return (
      <span className='propertyTitleHeartShow'>
        {selected && <> <img
        title={I18n.t('recreations.heart.remove')}
        onClick={() => this.markRecreation()}
        src={redHeart}/> </> }
  {!selected && <> <img
  title={I18n.t('recreations.heart.add')}
  onClick={() => this.markRecreation()}
  src={emptyHeart}/> </>}
  </span>);
}
}

